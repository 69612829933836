import { Col, Image, Row } from "antd";
import packageJson from "../../../package.json";
import React, { useEffect, useState } from "react";
import Contrainer from "../../components/Contrainer/Contrainer";
import Header from "../../components/Header/Header";
import { AuthDatasource } from "../../datasource/loginDatasource";
import { EmployeeEntity } from "../../entities/EmployeeEntity";
import color from "../../resource/color";
import image from "../../resource/image";
import Text from "../../components/Text/Text";
import { PermissionReportDatasource } from "../../datasource/permissionReportDatasource";

const IndexHome: React.FC = () => {
  const version = packageJson.version;
  const token = localStorage.getItem("rainbowToken");
  const [emp, setEmp] = useState<EmployeeEntity>();

  const login = async () => {
    await AuthDatasource.getEmployeeByToken(token).then(async (emp) => {
      localStorage.setItem("rainbowProfile", JSON.stringify(emp.responseData));
      setEmp(emp.responseData);
      await PermissionReportDatasource.getPermissionByEmpId(
        emp.responseData.employeeId
      ).then((res) => {
        if (res.success) {
          localStorage.setItem(
            "rainbowPermission",
            JSON.stringify(res.responseData)
          );
        } else {
          localStorage.setItem("rainbowPermission", JSON.stringify(null));
        }
      });
    });
  };

  useEffect(() => {
    login();
  }, []);
  return (
    <Contrainer>
      <Col style={{ height: "100%" }}>
        <Header
          label={`ยินดีต้อนรับ ${emp?.firstname}  ${emp?.lastname}`}
          color={color.navy}
          fontSize={30}
          headerHeight={"60Px"}
        />
      </Col>
      <Col>
        <Image src={image.homePage} preview={false} />
      </Col>
      <Row justify={"center"}>
        <Text fontSize={14} fontWeight={600}>
          v. {version}
        </Text>
      </Row>
    </Contrainer>
  );
};
export default IndexHome;
