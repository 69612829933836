import { Content } from "antd/lib/layout/layout";
import color from "../../resource/color";

interface Props {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

function Contrainer({ children }: Props) {
  return (
    <Content
      style={{
        paddingTop: "8px",
        paddingRight: "50px",
        paddingLeft: "50px",
        backgroundColor: color.white,
      }}
    >
      {children}
    </Content>
  );
}

export default Contrainer;
