import { AxiosResponse } from "axios";
import { BASE_URL, httpClient } from "../config/develop-config";

const baseUrl = `${BASE_URL}`;

export class PermissionReportDatasource {
  static getPermissionReport(params: object) {
    return httpClient
      .get(`${baseUrl}/permission-report`, { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static getPermissionById(id: string) {
    return httpClient
      .get(`${baseUrl}/permission-report/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static createPermission = async (data: any) => {
    return await httpClient
      .post(`${baseUrl}/permission-report`, data)
      .then((res: AxiosResponse) => res.data)
      .catch((err) => console.log(err));
  };
  static deletePermissionById(id: string) {
    return httpClient
      .delete(`${baseUrl}/permission-report/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static getPermissionByEmpId(id: string) {
    return httpClient
      .get(`${baseUrl}/permission-report/permission-employee/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
