import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Input,
  Select,
  Avatar,
  Radio,
  Divider,
  Button,
  Modal,
  Space,
  Checkbox,
  Badge,
  Form,
  ConfigProvider,
  message,
} from "antd";
import Text from "../../components/Text/Text";
import color from "../../resource/color";
import Header from "../../components/Header/Header";
import Contrainer from "../../components/Contrainer/Contrainer";
import { Footer } from "antd/lib/layout/layout";
import {
  SCORE_MAPPING,
  SCORE_MAPPING_COLOR,
  SCORE_MAPPING_COLORS,
  SCORE_MAPPING_DES,
} from "../../definitions/score";
import { YearList } from "../../definitions/Years";
import { PeriodList } from "../../definitions/Period";
import { EmployeeApproveDatasource } from "../../datasource/EmploeeApprove";
import {
  ExclamationCircleOutlined,
  HomeFilled,
  RetweetOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { SelfReflcetionDatasource } from "../../datasource/selfReflectionDatasoure";
import { SettingDatasource } from "../../datasource/settingDatasource";
import { GrowthDatasource } from "../../datasource/growthDatasource";
import { useForm } from "antd/lib/form/Form";
import Swal from "sweetalert2";
import { pmsDatasource } from "../../datasource/pmsDatabase";
const { TextArea } = Input;

const IndexSupervisor: React.FC = () => {
  const [form] = useForm();
  const [modal, contextModal] = Modal.useModal();
  const navigate = useNavigate();
  const profile = JSON.parse(localStorage.getItem("rainbowProfile")!);
  const [messageApi, contextHolder] = message.useMessage();
  const resolutionList = [
    {
      key: "pmsStay",
      name: "Stay",
      description:
        "Stay (อะไรบ้างที่เราคิดว่าพนักงานทำได้ดี ควรชื่นชมและอยากให้รักษาสิ่งนี้ไว้)",
    },
    {
      key: "pmsStart",
      name: "Start",
      description:
        "Start (สิ่งที่พนักงานยังไม่ได้ทำ แต่ถ้าเริ่มทำมันจะดีสำหรับพนักงาน)",
    },
    {
      key: "pmsStop",
      name: "Stop",
      description:
        "Stop (สิ่งที่เราเห็นว่าหากพนักงานไม่มีพฤติกรรมนี้ แล้วจะทำให้มีประสิทธิภาพมากขึ้น)",
    },
    {
      key: "pmsCareerPath",
      name: "Career Path",
      description:
        "เส้นทาง ความก้าวหน้าในสายอาชีพ ของพนักงานคนนี้ หรือ สิ่งที่พนักงานคนนี้ควรได้รับการพัฒนา เพื่อความก้าวหน้าในสายงาน",
    },
    {
      key: "pmsOverall",
      name: "Overall",
      description: "ภาพรวม ทั้งหมด หรือ Note  อื่นๆ สำหรับการพูดคุย",
    },
  ];
  const [loading, setLoading] = useState<boolean>();
  const yearList = YearList();
  const [year, setYear] = useState<any>(new Date().getFullYear());
  const [month, setMonth] = useState<number>(
    new Date().getMonth() + 1 >= 10 ? 14 : 13
  );
  const [pms, setPms] = useState<any>([]);
  const [empList, setEmpList] = useState<any>([]);
  const [empSelected, setEmpSelected] = useState<string>();
  const [empSelfReflce, setEmpSalfReflce] = useState<any>([]);
  const [selfReflce, setSelfReflce] = useState<any>([]);
  const [needSupport, setNeedSupport] = useState<any>([]);
  const [resolution, setResolution] = useState<any>([]);
  const [overall, setOverall] = useState<any>([]);
  const [checkSubmit, setCheckSubmit] = useState<boolean>(false);
  const [score, setScore] = useState<any>([]);
  const [growthPoten, setGrowthPoten] = useState<any>([]);

  const getPms = async () => {
    const data: any = await pmsDatasource
      .getPMS({
        employeeId: empSelected,
        supervisorEmployeeId: profile.employeeId,
        pmsYear: year,
        pmsMonth: month,
      })
      .then(async (res) => {
        return res;
      });
    setPms(data.responseData);
    if (data.success) {
      await data?.responseData?.pmsScore?.forEach((s: any) => {
        form.setFieldValue(`score_${s.scoreConditionId}`, s.pmsScoreValue);
        form.setFieldValue(`text_${s.scoreConditionId}`, s.pmsScoreDesc);
      });
      form.setFieldsValue(data.responseData);
    } else {
      setPms({ isSubmit: false });
      form.resetFields();
      form.setFieldsValue({
        employeeId: empSelected,
        supervisorEmployeeId: profile.employeeId,
        pmsYear: year,
        pmsMonth: month,
        updateBy: profile.firstname + " " + profile.lastname,
        isSubmit: false,
      });
    }
  };
  const getEmployee = async () => {
    await EmployeeApproveDatasource.getEmployeeApprove(profile.employeeId).then(
      (res) => {
        if (res.success) {
          setEmpList(
            res.responseData
              .filter((e: any) => e.employee !== null)
              .map((x: any) => {
                return x.employee;
              })
          );
          setLoading(false);
        } else {
          setLoading(true);
        }
      }
    );
  };
  const getSelfReflce = async () => {
    const condition = await SettingDatasource.getSelfCondition(year).then(
      (res) => res
    );
    await SelfReflcetionDatasource.getSelfReflection({
      employeeId: empSelected,
      selfReflectionYear: year,
      selfReflectionMonth: month,
    }).then(async (res) => {
      setEmpSalfReflce(res);
      if (res.length > 0) {
        setCheckSubmit(res[0].isSubmit);
      } else {
        setCheckSubmit(false);
      }
      const mapSelf = await condition.find((x: any) => {
        const filter = x?.sectionOrder === 1;
        if (filter) {
          return x;
        }
        return filter;
      });
      const newMapSelf = await mapSelf?.sectionSubjectDetail?.map(
        (con: any) => {
          const sectionOne = res?.find((x: any) => {
            const filter = x?.selfReflectionSetting?.sectionOrder === 1;
            if (filter) {
              return x;
            }
          })?.selfReflectionValue;
          return {
            ...con,
            value: sectionOne?.find((a: any) => a.key === con.key)?.value || "",
          };
        }
      );
      const mapNeedSupport = await condition.find((x: any) => {
        const filter = x?.sectionOrder === 2;
        if (filter) {
          return x;
        }
        return filter;
      });
      const newMapSupport = await mapNeedSupport?.sectionSubjectDetail?.map(
        (con: any) => {
          const sectionTwo = res?.find((x: any) => {
            const filter = x?.selfReflectionSetting?.sectionOrder === 2;
            if (filter) {
              return x;
            }
          })?.selfReflectionValue;
          return {
            ...con,
            value: sectionTwo?.find((a: any) => a.key === con.key)?.value || "",
          };
        }
      );
      const mapResolution = await condition.find((x: any) => {
        const filter = x?.sectionOrder === 3;
        if (filter) {
          return x;
        }
        return filter;
      });
      const newMapResol = await mapResolution?.sectionSubjectDetail?.map(
        (con: any) => {
          const sectionThree = res?.find((x: any) => {
            const filter = x?.selfReflectionSetting?.sectionOrder === 3;
            if (filter) {
              return x;
            }
          })?.selfReflectionValue;
          return {
            ...con,
            value:
              sectionThree?.find((a: any) => a.key === con.key)?.value || "",
          };
        }
      );
      const mapOverall = await condition.find((x: any) => {
        const filter = x?.sectionOrder === 4;
        if (filter) {
          return x;
        }
        return filter;
      });
      const newMapOverall = await mapOverall?.sectionSubjectDetail?.map(
        (con: any) => {
          const sectionFour = res?.find((x: any) => {
            const filter = x?.selfReflectionSetting?.sectionOrder === 4;
            if (filter) {
              return x;
            }
          })?.selfReflectionValue;
          return {
            ...con,
            value:
              sectionFour?.find((a: any) => a.key === con.key)?.value || "",
          };
        }
      );
      setNeedSupport(newMapSupport);
      setSelfReflce(newMapSelf);
      setResolution(newMapResol);
      setOverall(newMapOverall);
    });
  };
  const getScore = async () => {
    await SettingDatasource.getScoreCondition().then((res) => {
      setScore(res);
    });
  };
  const getGrowthPotential = async () => {
    await GrowthDatasource.getGrowthCondition().then((res) => {
      setGrowthPoten(res);
    });
  };

  useEffect(() => {
    !empSelected && getEmployee();
    if (empSelected && year && month) {
      getPms();
      getSelfReflce();
      getScore();
      getGrowthPotential();
    }
  }, [empSelected, year, month, checkSubmit]);

  const submitPms = async () => {
    const confirmed = await modal.confirm({
      title: (
        <>
          <Text fontWeight={700}>ยืนยันการ Submit PMS </Text>
          <br />
          <Text fontWeight={500} color="error">
            กรุณาตรวจสอบข้อมูลให้ถูกต้องก่อนการ Submit{" "}
          </Text>
          <Text fontWeight={500} color="error">
            เนื่องจากจะไม่สามารถกลับมาแก้ไขมูลย้อนหลังได้
          </Text>
        </>
      ),
      icon: <ExclamationCircleOutlined style={{ fontSize: "25px" }} />,
      content: "",
      okText: (
        <Text color="white" fontWeight={700}>
          ยืนยัน
        </Text>
      ),
      cancelText: <Text fontWeight={700}>ยกเลิก</Text>,
      centered: true,
    });
    if (confirmed) {
      const validate = await form.validateFields().catch((e) => {
        if (e) {
          Swal.fire({
            position: "center",
            icon: "warning",
            title: "กรุณากรอกข้อมูลให้ครบ",
            showConfirmButton: true,
            width: 360,
          });
          return false;
        }
      });
      if (validate) {
        form.setFieldValue("isSubmit", true);
        onSaveResult();
      }
    }
  };
  const onSaveResult = async () => {
    const scoreData = score.map((s: any) => {
      const findValue = form.getFieldValue(`score_${s.scoreConditionId}`);
      const findText = form.getFieldValue(`text_${s.scoreConditionId}`);
      const findScore = pms?.pmsScore?.find(
        (p: any) => p.scoreConditionId === s.scoreConditionId
      );
      if (findScore) {
        return {
          pmsId: findScore.pmsId,
          pmsScoreId: findScore.pmsScoreId,
          scoreConditionId: s.scoreConditionId,
          pmsScoreValue: findValue || "",
          pmsScoreDesc: findText || "",
        };
      } else {
        return {
          scoreConditionId: s.scoreConditionId,
          pmsScoreValue: findValue || "",
          pmsScoreDesc: findText || "",
        };
      }
    });
    form.setFieldValue("pmsScore", scoreData);
    const payload = form.getFieldsValue(true);
    await pmsDatasource.createSelfRefect(payload).then(async (res) => {
      if (res.success) {
        messageApi.open({
          type: "success",
          content: "Auto Save",
        });
        await res?.responseData.responseData?.pmsScore?.forEach((s: any) => {
          form.setFieldValue(`score_${s.scoreConditionId}`, s.pmsScoreValue);
          form.setFieldValue(`text_${s.scoreConditionId}`, s.pmsScoreDesc);
        });
        form.setFieldsValue(res?.responseData.responseData);
        setPms(res.responseData.responseData);
      }
    });
  };
  const unsubmit = async () => {
    const confirmed = await modal.confirm({
      title: <Text fontWeight={700}>ยืนยัน Unsubmit Self Reflection</Text>,
      icon: <ExclamationCircleOutlined style={{ fontSize: "25px" }} />,
      content: "",
      okText: (
        <Text color="white" fontWeight={700}>
          ยืนยัน
        </Text>
      ),
      cancelText: <Text fontWeight={700}>ยกเลิก</Text>,
      centered: true,
    });
    if (confirmed) {
      empSelfReflce.forEach(async (id: any) => {
        await SelfReflcetionDatasource.submitSelfRefect(
          id.selfReflectionId
        ).then((res) => {
          if (res.success) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Unsumbit สำเร็จ",
              showConfirmButton: false,
              timer: 2000,
              width: 250,
            });
            getSelfReflce();
          }
        });
      });
    }
  };

  const Title = () => {
    return (
      <>
        <Row justify={"space-between"} style={{ padding: "10px" }}>
          <Col span={12}>
            <Row justify={"space-between"}>
              <Col span={12}>
                <Text fontWeight={700} fontSize={20} color="navy">
                  Employee Name :
                </Text>
                <br />
                <Text color="navy">ชื่อพนักงาน</Text>
              </Col>
              <Col span={12}>
                <Select
                  allowClear={empSelected ? true : false}
                  showSearch
                  bordered={!empSelected ? true : false}
                  options={empList?.map((y: any) => {
                    return {
                      label: (
                        <Row justify={"space-around"}>
                          <Text
                            color="darkyellow"
                            fontWeight={700}
                            fontSize={20}
                          >
                            {y?.firstname + " " + y?.lastname}
                          </Text>
                        </Row>
                      ),
                      value: y?.employeeId,
                      key: y?.firstname + " " + y?.lastname,
                    };
                  })}
                  virtual={false}
                  style={{
                    width: "95%",
                    height: "80%",
                    overflow: "scroll",
                  }}
                  defaultValue={empSelected}
                  onChange={(e) => setEmpSelected(e)}
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) => {
                    return (option?.key.toUpperCase() ?? "").includes(
                      input.toUpperCase()
                    );
                  }}
                  filterSort={(optionA: any, optionB: any) => {
                    return (optionA?.key ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.key ?? "").toLowerCase());
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row justify={"space-between"}>
              <Col span={12}>
                <Text fontWeight={700} fontSize={20} color="navy">
                  Supervisor Name :
                </Text>
                <br />
                <Text color="navy">ชื่อผู้บังคับบัญชา</Text>
              </Col>
              <Col span={12}>
                <Row justify={"space-around"}>
                  <Text fontWeight={700} fontSize={20} color="green">
                    {profile.firstname} {profile.lastname}
                  </Text>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify={"space-between"} style={{ padding: "10px" }}>
          <Col span={12}>
            <Row justify={"space-between"}>
              <Col span={12}>
                <Text fontWeight={700} fontSize={20} color="navy">
                  Position :
                </Text>
                <br />
                <Text color="navy">ตำแหน่ง</Text>
              </Col>
              {empSelected && (
                <Col span={12}>
                  <Row justify={"space-around"}>
                    <Text fontWeight={700} fontSize={20} color="green">
                      {empList?.find((x: any) => x.employeeId === empSelected)
                        .position?.positionName || ""}
                    </Text>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
          <Col span={12}>
            <Row justify={"space-between"}>
              <Col span={6}>
                <Text fontWeight={700} fontSize={20} color="navy">
                  Year :
                </Text>
                <br />
                <Text color="navy">ปี ค.ศ.</Text>
              </Col>
              <Col span={6}>
                <Select
                  bordered={false}
                  onChange={(e) => setYear(e)}
                  style={{ width: "80%", height: "80%" }}
                  defaultValue={year}
                  options={yearList.map((y: any) => {
                    return {
                      label: (
                        <Text color="darkyellow" fontWeight={700}>
                          {y}
                        </Text>
                      ),
                      value: y,
                    };
                  })}
                />
              </Col>
              <Col span={6}>
                <Text fontWeight={700} fontSize={20} color="navy">
                  Types :
                </Text>
                <br />
                <Text color="navy">ประเภท</Text>
              </Col>
              <Col span={6}>
                <Select
                  bordered={false}
                  style={{
                    width: "80%",
                    height: "80%",
                  }}
                  onChange={(e) => setMonth(e)}
                  defaultValue={month}
                  options={PeriodList.map((y) => {
                    return {
                      label: (
                        <Text color="darkyellow" fontWeight={700}>
                          {y.month}
                        </Text>
                      ),
                      value: y.key,
                    };
                  })}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify={"space-between"} style={{ padding: "10px" }}>
          <Col span={12}>
            <Row justify={"space-between"}>
              <Col span={12}>
                <Text fontWeight={700} fontSize={20} color="navy">
                  Job Description (JD) :
                </Text>
                <br />
                <Text color="navy">หน้าที่</Text>
              </Col>
              {profile?.position?.team?.department?.company?.companyId ===
                `1` && (
                <Col span={12}>
                  <Row justify={"space-around"}>
                    <Text
                      fontWeight={700}
                      fontSize={20}
                      color="darkyellow"
                      style={{ cursor: "pointer" }}
                    >
                      <a
                        href="https://icpladda.monday.com/boards/521144129/views/145281099"
                        target="_blank"
                        style={{
                          color: color.darkyellow,
                          textDecoration: "underline",
                        }}
                      >
                        คลิกเพื่อดู JD
                      </a>
                    </Text>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
          <Col span={6}>
            <Row justify={"space-between"}>
              <Col span={12}>
                <Text fontWeight={700} fontSize={20} color="navy">
                  PMS Manual :
                </Text>
                <br />
                <Text color="navy">คู่มือ</Text>
              </Col>
              <Text
                fontWeight={700}
                fontSize={20}
                color="darkyellow"
                style={{ cursor: "pointer" }}
              >
                <a
                  href="https://docs.google.com/presentation/d/15dYi_rxdH_DlukRrU0GO0pof1xKbBqlzna7N56HRpHc/edit?usp=sharing"
                  target="_blank"
                  style={{
                    color: color.darkyellow,
                    textDecoration: "underline",
                  }}
                >
                  คู่มือการทำ PMS
                </a>
              </Text>
            </Row>
          </Col>
          <Col span={6}>
            <Row justify={"end"}>
              <Col span={12}>
                {checkSubmit && !pms?.isSubmit && empSelected && (
                  <Button
                    style={{ backgroundColor: color.navy, height: "35px" }}
                    icon={
                      <RetweetOutlined
                        style={{ color: "white", fontSize: "19px" }}
                      />
                    }
                    onClick={() => unsubmit()}
                  >
                    <Text color="white" fontWeight={700}>
                      UNSUBMIT
                    </Text>
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };
  const partOne = (
    <>
      <Header label="ช่วงที่ 1" fontSize={24} color={color.green} />
      <br />
      <Row justify={"center"} gutter={8}>
        <Col>
          <Avatar
            shape="square"
            size="large"
            style={{
              backgroundColor: color.yellow,
              borderColor: color.gray,
            }}
          />
          <Text fontWeight={700}> พื้นหลังสีเหลืองคือ ขั้นตอนการทำ PMS</Text>
        </Col>
        <Col>
          <Avatar
            shape="square"
            size="large"
            style={{
              backgroundColor: color.pink,
              borderColor: color.gray,
            }}
          />
          <Text fontWeight={700}>
            {" "}
            พื้นหลังสีชมพูคือ ข้อมูลที่พนักงานส่งเข้ามา
          </Text>
        </Col>
      </Row>
      <br />
      <Row justify={"center"}>
        <Col span={12}>
          <Header
            label="1. สร้างบรรยากาศ (5-10 นาที)"
            color={color.navy}
            //headerWidth="22%"
            fontSize={20}
          />
        </Col>
      </Row>
      <Row justify={"center"}>
        <Card
          style={{
            backgroundColor: color.yellow,
          }}
        >
          <Text fontWeight={700}>วัตถุประสงค์ </Text>
          <Text fontWeight={400}>
            เพื่อสร้างสภาพแวดล้อมที่ Open สำหรับการทำ PMS โดยเปิดโอกาส
            ให้พนักงานได้เล่าเรื่องของตัวเอง ของทีม และขององค์กร ในปีที่ผ่านมา
          </Text>
          <br />
          <Text fontWeight={700}>ตัวอย่างคำถาม</Text>
          <br />
          <Text fontWeight={400}>
            ● คุณเห็นว่าตัวเอง ณ วันที่ 1 มกราคม เทียบกับปัจจุบัน
            แตกต่างกันอย่างไรบ้าง
          </Text>
          <br />
          <Text fontWeight={400}>
            ● การพัฒนาการของทีมคุณเป็นอย่างไรในปีที่ผ่านมา
          </Text>
          <br />
          <Text fontWeight={400}>
            ● ในปีที่ผ่านมาองค์กรเราเปลี่ยนแปลงไปยังไงบ้าง อะไรที่คุณเห็นว่าดี
            อะไรที่คุณ เห็นว่าควรระวัง
          </Text>
          <br />
          <Text fontWeight={400}>● ชีวิตโดยรวมคุณเป็นยังไงบ้างในช่วงนี้</Text>
        </Card>
      </Row>
      <br />
      <Row justify={"center"} style={{ padding: "20px" }}>
        <ConfigProvider
          theme={{
            components: {
              Checkbox: {
                colorBgContainerDisabled: color.navy,
              },
            },
          }}
        >
          <Form.Item name="isReviewJD" valuePropName="checked">
            <Checkbox onChange={onSaveResult} disabled={pms?.isSubmit}>
              <Text fontWeight={700} fontSize={20}>
                ให้พนักงานรีวิว JD (เฉพาะ คนที่มี)
              </Text>
            </Checkbox>
          </Form.Item>
        </ConfigProvider>
      </Row>
      <Header
        label="Self-Reflection ของพนักงาน"
        fontSize={24}
        color={color.green}
      />
      <br />
      <Row
        justify={checkSubmit ? "space-between" : "center"}
        gutter={8}
        style={{ padding: checkSubmit ? "" : "6%" }}
      >
        {checkSubmit ? (
          selfReflce?.map((x: any) => (
            <>
              <Col span={12}>
                <Row justify={"space-around"}>
                  <Col span={18}>
                    <Header
                      label={x?.sectionSubjectTitle}
                      color={color.navy}
                      fontSize={20}
                    />
                  </Col>
                </Row>
                <Card
                  style={{
                    backgroundColor: color.pink,
                  }}
                >
                  <TextArea
                    value={`คำตอบ : ${x?.value}`}
                    bordered={false}
                    autoSize={{ minRows: 7, maxRows: 7 }}
                    style={{
                      fontFamily: "Sarabun",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                  />
                </Card>
                <br />
              </Col>
            </>
          ))
        ) : (
          <Text fontSize={36} color="gray">
            พนักงานยังไม่ Submit การประเมิน
          </Text>
        )}
      </Row>
      <Header
        label="Need Support ของพนักงาน"
        fontSize={24}
        color={color.green}
      />
      <br />
      <Row
        justify={checkSubmit ? "space-between" : "center"}
        gutter={8}
        style={{ padding: checkSubmit ? "" : "6%" }}
      >
        {checkSubmit ? (
          needSupport?.map((x: any) => (
            <>
              <Col span={12}>
                <Row justify={"space-around"}>
                  <Col span={18}>
                    <Header
                      label={x?.sectionSubjectTitle}
                      color={color.navy}
                      fontSize={16}
                    />
                  </Col>
                </Row>
                <Card
                  style={{
                    backgroundColor: color.pink,
                  }}
                >
                  <TextArea
                    value={`คำตอบ : ${x?.value}`}
                    bordered={false}
                    autoSize={{ minRows: 7, maxRows: 7 }}
                    style={{
                      fontFamily: "Sarabun",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                  />
                </Card>
                <br />
              </Col>
            </>
          ))
        ) : (
          <Text fontSize={36} color="gray">
            พนักงานยังไม่ Submit การประเมิน
          </Text>
        )}
      </Row>
    </>
  );
  const partTwo = (
    <>
      <Header label="ช่วงที่ 2" fontSize={24} color={color.green} />
      <br />
      <Row justify={"center"}>
        <Col span={12}>
          <Header
            label="2. บอกผลประเมิน และ What is next 20 - 30 นาที"
            color={color.navy}
            fontSize={20}
          />
        </Col>
      </Row>
      <Row justify={"center"}>
        <Card
          style={{
            backgroundColor: color.yellow,
          }}
        >
          <Text fontWeight={700}>วัตถุประสงค์ </Text>
          <Text fontWeight={400}>
            ให้พนักงานได้ทราบผลประเมินของตัวเอง และ เปิดโอกาสให้พนักงานได้
            ร้องขอการ Support ในเรื่องต่างๆ
          </Text>
          <br />
          <Text fontWeight={700}>ขั้นตอนดังนี้</Text>
          <br />
          <Text fontWeight={400}>
            1. ให้หัวหน้างานบอกคะแนนในภาพรวม ให้แก่พนักงานทราบ โดยไม่ต้องบอก
            คะแนนของ 4 pillars
          </Text>
          <br />
          <Text fontWeight={400}>
            2.ให้หัวหน้างาน คุยในเรื่องต่างๆ ตัวอย่าง ดังต่อไปนี้
          </Text>
          <br />
          <Text fontWeight={400} fontSize={14}>
            ● KCI ผลงานหลัก
          </Text>
          <br />
          <Text fontWeight={400} fontSize={14}>
            ● Culture ICPG
          </Text>
          <br />
          <Text fontWeight={400} fontSize={14}>
            ● การบริหารจัดการทีม
          </Text>
          <br />
          <Text fontWeight={400} fontSize={14}>
            ● STAY  START  STOP
          </Text>
          <br />
          <Text fontWeight={400} fontSize={14}>
            ● Career Path  ความก้าวหน้าในสายอาชีพ
          </Text>
          <br />
          <Text fontWeight={400} fontSize={14}>
            ● Training  การอบรมต่างๆ
          </Text>
          <br />
          <Text fontWeight={400} fontSize={14}>
            ● สิ่งที่ต้องการให้ Support เพิ่มเติม
          </Text>
        </Card>
      </Row>
      <br />
      {score
        .filter((a: any) => a.scoreConditionName === "Final Score")
        .map((x: any) => (
          <>
            <Col span={8}>
              <Header
                label={x?.scoreConditionName}
                color={color.navy}
                fontSize={20}
              />
            </Col>
            <br />
            <Row justify={"space-between"}>
              {x?.scoreConditionDetail?.map((y: any) => (
                <ConfigProvider
                  theme={{
                    components: {
                      Radio: {
                        colorPrimary: SCORE_MAPPING_COLORS[y.value],
                        colorBgContainerDisabled: !y.value
                          ? SCORE_MAPPING_COLORS[y.value]
                          : "",
                      },
                    },
                  }}
                >
                  <Form.Item
                    name={`score_${x.scoreConditionId}`}
                    rules={[
                      {
                        required: true,
                        message: "*โปรดระบุคะแนน",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={onSaveResult}
                      disabled={pms?.isSubmit}
                    >
                      <Radio
                        value={y.value}
                        style={{ display: "inline-grid", placeItems: "center" }}
                      >
                        <Col style={{ display: "grid", placeItems: "center" }}>
                          <Text
                            color={SCORE_MAPPING_COLOR[y.value]}
                            fontWeight={700}
                            fontSize={20}
                          >
                            {SCORE_MAPPING[y.value]}
                          </Text>
                        </Col>
                        <Col style={{ display: "grid", placeItems: "center" }}>
                          <Text
                            color={SCORE_MAPPING_COLOR[y.value]}
                            fontWeight={700}
                            fontSize={18}
                            style={{
                              display: "inline-grid",
                              placeItems: "center",
                            }}
                          >
                            {SCORE_MAPPING_DES[y.value]}
                          </Text>
                        </Col>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </ConfigProvider>
              ))}
            </Row>
            <Divider style={{ backgroundColor: color.green, padding: "3px" }} />
            <br />
          </>
        ))}
      <Row justify={"center"}>
        <Card
          style={{
            backgroundColor: "#E1E5F2",
            textAlign: "center",
          }}
        >
          <Text fontWeight={700} color="error" fontSize={30}>
            บอกคะแนนในภาพรวม ให้แก่พนักงานทราบ
          </Text>
          <br />
          <Text fontWeight={700} color="error" fontSize={30}>
            โดยไม่ต้องบอก คะแนนของ 4 pillars
          </Text>
          <br />
          <Text fontWeight={700} color="error" fontSize={30}>
            ให้แนะนำเพื่อการพัฒนาเท่านั้น
          </Text>
        </Card>
      </Row>
      <br />
      {score
        .filter((a: any) => a.scoreConditionName !== "Final Score")
        .map((x: any) => (
          <>
            <Col span={8}>
              <Header
                label={x?.scoreConditionName}
                color={color.navy}
                fontSize={20}
              />
            </Col>
            <br />
            <Row justify={"space-between"}>
              {x?.scoreConditionDetail?.map((y: any) => (
                <ConfigProvider
                  theme={{
                    components: {
                      Radio: {
                        colorPrimary: SCORE_MAPPING_COLORS[y.value],
                        colorBgContainerDisabled: !y.value
                          ? SCORE_MAPPING_COLORS[y.value]
                          : "",
                      },
                    },
                  }}
                >
                  <Form.Item
                    name={`score_${x.scoreConditionId}`}
                    rules={[
                      {
                        required: true,
                        message: "*โปรดระบุคะแนน",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onSaveResult}>
                      <Radio
                        value={y.value}
                        style={{ display: "inline-grid", placeItems: "center" }}
                        disabled={pms?.isSubmit}
                      >
                        <Col style={{ display: "grid", placeItems: "center" }}>
                          <Text
                            color={SCORE_MAPPING_COLOR[y.value]}
                            fontWeight={700}
                            fontSize={20}
                          >
                            {SCORE_MAPPING[y.value]}
                          </Text>
                        </Col>
                        <Col style={{ display: "grid", placeItems: "center" }}>
                          <Text
                            color={SCORE_MAPPING_COLOR[y.value]}
                            fontWeight={700}
                            fontSize={18}
                            style={{
                              display: "inline-grid",
                              placeItems: "center",
                            }}
                          >
                            {SCORE_MAPPING_DES[y.value]}
                          </Text>
                          <TextArea
                            style={{
                              display: "inline-grid",
                              placeItems: "center",
                              fontFamily: "Sarabun",
                              fontWeight: "bold",
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                            value={y.description}
                            autoSize
                            bordered={false}
                          />
                        </Col>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </ConfigProvider>
              ))}
            </Row>
            <Text fontWeight={700} style={{ padding: "25%" }}>
              จงอธิบายเหตุผลเกณฑ์การให้คะแนนพนักงาน...
            </Text>
            <Row justify={"center"}>
              <Col span={12}>
                <Form.Item
                  name={`text_${x.scoreConditionId}`}
                  rules={[
                    {
                      required: true,
                      message: "*โปรดระบุเหตุผลการให้คะแนน",
                    },
                  ]}
                >
                  <TextArea
                    rows={5}
                    style={{
                      width: "100%",
                      color: color.black,
                      fontFamily: "Sarabun",
                      fontWeight: "bold",
                    }}
                    autoSize={{ minRows: 5, maxRows: 5 }}
                    placeholder="ข้อความ..."
                    onBlur={onSaveResult}
                    disabled={pms?.isSubmit}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider style={{ backgroundColor: color.green, padding: "3px" }} />
            <br />
          </>
        ))}
    </>
  );
  const partThree = (
    <>
      <Col span={12}>
        <Header
          label="Growth Potential (ศักยภาพในการเติบโต)"
          color={color.navy}
          fontSize={20}
        />
      </Col>
      <br />

      <Form.Item
        name="growthPotentialId"
        rules={[
          {
            required: true,
            message: (
              <div style={{ paddingLeft: "15%", paddingBottom: "2%" }}>
                * โปรดระบุ Growth Potential
              </div>
            ),
          },
        ]}
      >
        <Radio.Group
          style={{ paddingLeft: "15%", paddingBottom: "2%" }}
          onChange={onSaveResult}
          disabled={pms?.isSubmit}
        >
          <Space direction="vertical">
            {growthPoten?.map((x: any) => (
              <ConfigProvider
                theme={{
                  components: {
                    Radio: {
                      colorBgContainerDisabled:
                        pms?.growthPotentialId === x?.growthPotentialId
                          ? color.navy
                          : "",
                    },
                  },
                }}
              >
                <Radio value={x?.growthPotentialId}>
                  <Text fontWeight={700}>{x?.growthPotentialName}</Text>
                </Radio>
              </ConfigProvider>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  );
  const partFour = (
    <>
      <Header label="Stay Start Stop" fontSize={24} color={color.green} />
      <br />
      {checkSubmit ? (
        resolution.map((x: any) => (
          <Row justify={"center"}>
            <Col span={15}>
              <Row justify={"center"}>
                <Col span={10}>
                  <Header
                    label={x.sectionSubjectTitle}
                    color={color.navy}
                    fontSize={20}
                  />
                </Col>
              </Row>
              <Card
                style={{
                  backgroundColor: color.pink,
                }}
              >
                <TextArea
                  value={`คำตอบ : ${x.value}`}
                  bordered={false}
                  autoSize={{ minRows: 4, maxRows: 4 }}
                  style={{
                    fontFamily: "Sarabun",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                />
              </Card>
              <br />
            </Col>
          </Row>
        ))
      ) : (
        <Row justify={"center"} style={{ padding: "6%" }}>
          <Text fontSize={36} color="gray">
            พนักงานยังไม่ Submit การประเมิน
          </Text>
        </Row>
      )}
      <Header
        label="ความคิดเห็นเพิ่มเติม Overall"
        fontSize={24}
        color={color.green}
      />
      <br />
      {checkSubmit ? (
        overall?.map((x: any) => (
          <Row justify={"center"}>
            <Col span={15}>
              <Row justify={"center"}>
                <Col span={10}>
                  <Header
                    label={x.sectionSubjectTitle}
                    color={color.navy}
                    fontSize={20}
                  />
                </Col>
              </Row>
              <Card
                style={{
                  backgroundColor: color.pink,
                }}
              >
                <TextArea
                  value={`คำตอบ : ${x.value}`}
                  bordered={false}
                  autoSize={{ minRows: 4, maxRows: 4 }}
                  style={{
                    fontFamily: "Sarabun",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                />
              </Card>
              <br />
            </Col>
          </Row>
        ))
      ) : (
        <Row justify={"center"} style={{ padding: "6%" }}>
          <Text fontSize={36} color="gray">
            พนักงานยังไม่ Submit การประเมิน
          </Text>
        </Row>
      )}
      <Divider style={{ backgroundColor: color.green, padding: "3px" }} />
      {resolutionList.map((x) => (
        <Row justify={"space-between"} gutter={16} style={{ padding: "8px" }}>
          <Col span={12}>
            <Badge.Ribbon
              text={
                <Text fontWeight={700} color="white" fontSize={24}>
                  {x.name}
                </Text>
              }
              placement="start"
              color={color.navy}
            >
              <Card
                style={{
                  width: "100%",
                  height: "85%",
                  backgroundColor: "#E1E5F2",
                  padding: "30px",
                }}
              >
                <Text fontWeight={700}>{x.description}</Text>
              </Card>
            </Badge.Ribbon>
          </Col>
          <Col span={12}>
            <Form.Item
              name={x.key}
              rules={[
                {
                  required: true,
                  message: "*โปรดระบุคำตอบ",
                },
              ]}
            >
              <TextArea
                rows={8}
                style={{
                  width: "100%",
                  color: color.black,
                  fontFamily: "Sarabun",
                  fontWeight: "bold",
                }}
                autoSize={{ minRows: 6, maxRows: 6 }}
                placeholder="กรุณาพิมพ์คำตอบที่นี่..."
                onBlur={onSaveResult}
                disabled={pms?.isSubmit}
              />
            </Form.Item>
          </Col>
        </Row>
      ))}
      <br />
    </>
  );
  const partFive = (
    <>
      <Header label="ช่วงที่ 3" fontSize={24} color={color.green} />
      <br />
      <Row justify={"center"}>
        <Col span={12}>
          <Header
            label="3. Feedback & Acknowledgement 5-10 นาที"
            color={color.navy}
            fontSize={20}
          />
        </Col>
      </Row>
      <Row justify={"center"}>
        <Card
          style={{
            backgroundColor: color.yellow,
          }}
        >
          <Text fontWeight={700}>วัตถุประสงค์ </Text>
          <Text fontWeight={400}>
            เพื่อ Demonstrate เป็นตัวอย่างในการเปิดใจรับ Feedback และพัฒนา
            รวมถึงเป็น โอกาสในการขอบคุณชื่นชมความตั้งใจ ความมุ่งมั่น
            และความยอดเยี่ยมของพนักงานในปีที่ผ่านมา
          </Text>
          <br />
          <Text fontWeight={700}>คำแนะนำ</Text>
          <br />
          <Text fontWeight={400}>● ไม่โต้แย้งในสิ่งที่พนักงาน Feedback</Text>
          <br />
          <Text fontWeight={400}>
            ● ถ้ารู้สึกว่าไม่พร้อมที่จะรับ Feedback ไม่ควรถามต่อ
          </Text>
          <br />
          <Text fontWeight={700}>ตัวอย่างคำถาม</Text>
          <br />
          <Text fontWeight={400}>
            ● มีอะไรที่อยาก Feedback เพื่อให้พี่ทำงานได้ดีขึ้นมั้ย
          </Text>
          <br />
          <Text fontWeight={400}>● มีอะไรอีกมั้ย ที่ไม่พูดไม่ได้มั้ย</Text>
          <br />
        </Card>
      </Row>
      <br />
      <Col style={{ paddingLeft: "9%" }}>
        <Text fontWeight={700}>Note :</Text>
        <Text fontWeight={600} color="black">
          {" "}
          ไว้ดูย้อนหลัง ในส่วนของการที่ ลูกทีมได้ Feedback ให้เรา
        </Text>
      </Col>
      <Row justify={"center"}>
        <Col span={20}>
          <Form.Item
            name="pmsFeedback"
            // rules={[
            //   {
            //     required: true,
            //     message: "*โปรดระบุคำตอบ",
            //   },
            // ]}
          >
            <TextArea
              rows={5}
              style={{
                width: "100%",
                color: color.black,
                fontFamily: "Sarabun",
                fontWeight: "bold",
              }}
              placeholder="ข้อความ..."
              onBlur={onSaveResult}
              disabled={pms?.isSubmit}
              autoSize={{ minRows: 5, maxRows: 5 }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"center"} style={{ padding: "20px" }}>
        <Space direction="vertical">
          <ConfigProvider
            theme={{
              components: {
                Checkbox: {
                  colorBgContainerDisabled: pms?.isEmployeeFeedback
                    ? color.navy
                    : "",
                },
              },
            }}
          >
            <Form.Item
              noStyle
              name="isEmployeeFeedback"
              valuePropName="checked"
            >
              <Checkbox onChange={onSaveResult} disabled={pms?.isSubmit}>
                <Text fontWeight={700}>
                  พนักงานได้มีการให้คำแนะนำ Feedback กับคุณเรียบร้อยแล้ว
                </Text>
              </Checkbox>
            </Form.Item>
          </ConfigProvider>
          <ConfigProvider
            theme={{
              components: {
                Checkbox: {
                  colorBgContainerDisabled: pms?.isGivePraise ? color.navy : "",
                },
              },
            }}
          >
            <Form.Item name="isGivePraise" noStyle valuePropName="checked">
              <Checkbox onChange={onSaveResult} disabled={pms?.isSubmit}>
                <Text fontWeight={700}>
                  คุณได้ให้คำชื่นชมกับพนักงานเรียบร้อยแล้ว
                </Text>
              </Checkbox>
            </Form.Item>
          </ConfigProvider>
        </Space>
      </Row>
    </>
  );

  if (loading) {
    return (
      <Modal
        title={
          <Row gutter={8} justify={"start"}>
            <Col>
              <WarningOutlined
                style={{ fontSize: "32px", color: color.warning }}
              />
            </Col>
            <Col>
              <Text fontSize={26} fontWeight={700} color="warning">
                Permission Warning
              </Text>
            </Col>
          </Row>
        }
        open={loading}
        centered
        closeIcon={null}
        onOk={() => navigate(`/`)}
        footer={(_, { OkBtn }) => <OkBtn />}
      >
        <Text fontWeight={500}>
          เมนูนี้สำหรับหัวหน้า เพื่อใช้ในการประเมินพนักงานเท่านั้น!
        </Text>
        <br />
        <Text fontWeight={500}>
          <u style={{ color: color.error }}>กรุณากดปุ่ม OK</u> เพื่อกลับสู่หน้า{" "}
          <HomeFilled /> Home
        </Text>
      </Modal>
    );
  } else {
    return (
      <>
        {contextModal}
        {contextHolder}
        <Contrainer>
          <Header
            label="PMS Form"
            fontSize={30}
            color={color.navy}
            headerHeight={"60Px"}
          />
          {Title()}
          {empSelected ? (
            <Form form={form}>
              {partOne}
              {partTwo}
              {partThree}
              {partFour}
              {partFive}
            </Form>
          ) : (
            <Row justify={"center"} style={{ paddingTop: "10%" }}>
              <Text fontSize={40} color="gray">
                กรุณาเลือกพนักงาน ก่อนการประเมิน
              </Text>
            </Row>
          )}
        </Contrainer>
        <Footer style={{ backgroundColor: color.white }}>
          {empSelected && checkSubmit && !pms?.isSubmit && (
            <Row justify={"center"}>
              <Button
                style={{ backgroundColor: color.navy, height: "40px" }}
                onClick={submitPms}
              >
                <Text color="white" fontWeight={700}>
                  SUBMIT
                </Text>
              </Button>
            </Row>
          )}
        </Footer>
      </>
    );
  }
};
export default IndexSupervisor;
