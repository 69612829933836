import "./App.css";
import { Routes } from "./Routes";
import { ConfigProvider } from "antd";

function App() {
  return (
    <ConfigProvider>
      <Routes />
    </ConfigProvider>
  );
}

export default App;
