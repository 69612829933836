import axios from "axios";
export const DEV_URL = "https://api-rainbow-dev.iconkaset.com";
export const BASE_URL =
  process.env.REACT_APP_NODE_ENV === "development"
    ? DEV_URL
    : process.env.REACT_APP_URL_HOST;

axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("rainbowToken") || "";
    const newConfig = {
      ...config,
      headers: {
        accept: "application/json",
        Authorization: token ? `Bearer ` + token : "",
      },
    };
    return newConfig;
  },
  async (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "https://system.icpladda.com/";
    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "https://system.icpladda.com/";
    }
    return error;
  }
);

export const httpClient = axios;

export const intanceAuth = axios.create({
  baseURL: BASE_URL,
});
