import color from "../resource/color";

export const SCORE_MAPPING: any = {
  "1": "B",
  "2": "M-",
  "3": "M",
  "4": "M+",
  "5": "E",
};
export const SCORE_MAPPING_DES: any = {
  "1": "ต่ำกว่าที่คาดหวัง",
  "2": "ตรงตามความคาดหวังบางส่วน",
  "3": "ตรงตามความคาดหวัง",
  "4": "สูงกว่าที่คาดหวัง",
  "5": "สูงกว่าที่คาดหวังมาก",
};

export const SCORE_MAPPING_COLOR: any = {
  "1": "error",
  "2": "orange",
  "3": "darkyellow",
  "4": "green",
  "5": "navy",
};

export const SCORE_MAPPING_COLORS: any = {
  "1": color.error,
  "2": color.orange,
  "3": color.darkyellow,
  "4": color.green,
  "5": color.navy,
};

export const ScoreList = ["5", "4", "3", "2", "1"];
