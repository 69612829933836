import { AxiosResponse } from "axios";
import { BASE_URL, httpClient, intanceAuth } from "../config/develop-config";

const baseUrl = `${BASE_URL}`;

export class reportDatasource {
  static getSelfSummaryReport(params: object) {
    return httpClient
      .get(`${baseUrl}/self-reflection/summary-department`, { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static getSelfDetailReport(params: object) {
    return httpClient
      .get(`${baseUrl}/self-reflection/summary-detail`, { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static getPmsSummaryReport(params: object) {
    return httpClient
      .get(`${baseUrl}/pms/summary-department`, { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static getPmsDetailReport(params: object) {
    return httpClient
      .get(`${baseUrl}/pms/summary-detail`, { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
