import { AxiosResponse } from "axios";
import { BASE_URL, httpClient, intanceAuth } from "../config/develop-config";

const baseUrl = `${BASE_URL}`;

export class departmentDatasource {
  static getDepartmentByCompId(compId: number) {
    return httpClient
      .get(`${baseUrl}/department/${compId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
