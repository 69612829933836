import { AxiosResponse } from "axios";
import { BASE_URL, httpClient, intanceAuth } from "../config/develop-config";

const baseUrl = `${BASE_URL}`;

export class AuthDatasource {
  static login(email: any) {
    return intanceAuth
      .post(`/auth/login-employee`, {
        email: email,
      })
      .then((response) => {
        localStorage.setItem("rainbowToken", response.data.accessToken);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static getEmployeeByToken(token: any) {
    return intanceAuth
      .get(`/auth/employee-token?token=${token}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
