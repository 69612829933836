export const YearList = () => {
  const yearList: any = [];
  const startY = 2023;
  const currentY = new Date().getFullYear();
  const diffY = currentY - startY + 2;
  for (let i = 0; diffY > i; i++) {
    yearList.push(startY + i);
  }
  return yearList;
};
