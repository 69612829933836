import {
  Col,
  ConfigProvider,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Form,
  Button,
  Pagination,
} from "antd";
import React, { useEffect, useState } from "react";
import Contrainer from "../../components/Contrainer/Contrainer";
import Header from "../../components/Header/Header";
import color from "../../resource/color";
import Text from "../../components/Text/Text";
import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined,
  PlusCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { CompanyList, COMPAY_MAPPING_DES } from "../../definitions/Company";
import Buttons from "../../components/Button/Button";
import { EmployeeApproveDatasource } from "../../datasource/EmploeeApprove";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/lib/form/Form";
import { EmployeeDatasource } from "../../datasource/employeeDatasource";
import Swal from "sweetalert2";

const ApprovalEmplyee: React.FC = () => {
  const profile = JSON.parse(localStorage.getItem("rainbowProfile")!);
  const [modal, contextModal] = Modal.useModal();
  const [form] = useForm();
  const [count, setCount] = useState(0);
  const [data, setData] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [comId, setComId] = useState<number>(1);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [searchEmp, setSearchEmp] = useState("");
  const [searchApprove, setSearchApprove] = useState("");
  const [empList, setEmpList] = useState<any>();
  const [approveList, setApproveList] = useState<any>();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const getData = async () => {
    await EmployeeApproveDatasource.getEmployeeApproval({
      companyId: comId,
      page,
      take: 8,
      search: search,
    }).then((res) => {
      if (res.success) {
        setCount(res.responseData.count);
        setData(res.responseData.data);
      } else {
        setCount(0);
        setData([]);
      }
    });
  };
  const getAllEmp = async () => {
    await EmployeeApproveDatasource.getAllEmployee({
      page: 1,
      take: 6,
      search: searchEmp,
    }).then((res) => {
      setEmpList(res.responseData);
    });
  };
  const getAllApprove = async () => {
    await EmployeeApproveDatasource.getAllEmployee({
      page: 1,
      take: 6,
      search: searchApprove,
    }).then((res) => {
      setApproveList(res.responseData);
    });
  };

  useEffect(() => {
    getData();
    getAllEmp();
    getAllApprove();
  }, [comId, page, search, searchEmp, searchApprove]);

  const columns = [
    {
      title: "Company",
      dataIndex: "company",
      render: (value: any, row: any, index: number) => {
        return {
          children: <Text>{COMPAY_MAPPING_DES[`${row.companyId}`]}</Text>,
        };
      },
    },
    {
      title: "Employee Name",
      dataIndex: "employee",
      render: (value: any, row: any, index: number) => {
        return {
          children: <Text>{row?.firstname + " " + row?.lastname}</Text>,
        };
      },
    },
    {
      title: "Approve Name",
      dataIndex: "approve",
      render: (value: any, row: any, index: number) => {
        const name = row?.employeeApprove?.employeeApproveDetail;
        return {
          children: (
            <Text>
              {(name?.firstname || "") + " " + (name?.lastname || "")}
            </Text>
          ),
        };
      },
    },
    {
      title: "Acknowledge",
      dataIndex: "acknowledge",
      width: "55%",
      render: (value: any, row: any, index: number) => {
        return {
          children: <Text>{row?.employeeApprove?.acknowledgeEmail}</Text>,
        };
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "5%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Row justify={"space-between"} gutter={24}>
              <Button
                style={{ color: color.navy }}
                shape="circle"
                icon={<EditFilled />}
                onClick={() =>
                  editEmp(row.employeeId, row?.employeeApprove?.Id)
                }
              />
              {row.employeeApprove && (
                <Button
                  style={{ color: color.navy }}
                  shape="circle"
                  icon={<DeleteFilled style={{ color: color.error }} />}
                  onClick={() => deleteEmp(row?.employeeApprove?.Id)}
                />
              )}
            </Row>
          ),
        };
      },
    },
  ];

  const findEmpComId = (id: any) => {
    const find = empList.find((x: any) => `${x.employeeId}` === `${id}`);
    form.setFieldValue("compantId", find.companyId);
  };

  const editEmp = async (empId: any, id?: any) => {
    setIsEdit(true);
    setOpen(!open);
    const dataEmp = await EmployeeDatasource.getEmployee(empId).then(
      (res) => res.responseData
    );
    setSearchEmp(dataEmp?.firstname || "");
    setSearchApprove(
      dataEmp?.employeeApprove?.employeeApproveDetail?.firstname || ""
    );
    id && form.setFieldValue("Id", id);
    form.setFieldsValue({
      employeeId: empId,
      companyId: dataEmp?.companyId,
      employeeApproveId: dataEmp?.employeeApprove?.employeeApproveId || "",
      acknowledgeEmail: dataEmp?.employeeApprove?.acknowledgeEmail || "",
    });
  };
  const deleteEmp = async (id: string) => {
    const confirmed = await modal.confirm({
      title: <Text fontWeight={700}>ยืนยันการลบพนักงาน</Text>,
      icon: <ExclamationCircleOutlined style={{ fontSize: "25px" }} />,
      content: "",
      okText: (
        <Text color="white" fontWeight={700}>
          ยืนยัน
        </Text>
      ),
      cancelText: <Text fontWeight={700}>ยกเลิก</Text>,
      centered: true,
    });
    if (confirmed) {
      await EmployeeApproveDatasource.deleteEmployee(id).then((res) => {
        if (res.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "ลบสำเร็จ",
            showConfirmButton: false,
            timer: 2000,
            width: 250,
          });
          getData();
        }
      });
    }
  };

  const submit = async () => {
    const validate = await form.validateFields().catch((e) => {
      return false;
    });
    if (validate) {
      const data = form.getFieldsValue(true);
      await EmployeeApproveDatasource.createEmployeeApprove(data).then(
        (res) => {
          if (res.success) {
            setOpen(!open);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "บันทึกสำเร็จ",
              showConfirmButton: false,
              width: 360,
              timer: 2000,
            });
            form.resetFields();
            getData();
          } else {
            Swal.fire({
              position: "center",
              icon: "warning",
              title: res.userMessage,
              showConfirmButton: true,
              width: 380,
            });
          }
        }
      );
    }
  };

  return (
    <>
      {contextModal}
      <Contrainer>
        <Header
          label="Approval Employee"
          color={color.navy}
          fontSize={30}
          headerHeight={"60Px"}
        />
        <br />
        <Row justify={"start"} gutter={16}>
          <Col span={2} style={{ paddingTop: "3px" }}>
            <Text fontWeight={700}>บริษัท :</Text>
          </Col>
          <Col span={3}>
            <Select
              style={{ width: "100%" }}
              value={comId}
              options={CompanyList.map((y: any) => {
                return {
                  label: y.company,
                  value: y.key,
                };
              })}
              onChange={(e) => setComId(e)}
            />
          </Col>
          <Col span={16}>
            <Input
              style={{ fontFamily: "Sarabun", width: "50%" }}
              suffix={<SearchOutlined />}
              placeholder="ค้นหาชื่อพนักงาน"
              onChange={(e) => {
                setPage(1);
                setSearch(e.target.value);
              }}
            />
          </Col>
          <Col span={2}>
            <Buttons
              bgColor={color.navy}
              onClick={() => {
                setIsEdit(false);
                setSearchEmp("");
                setSearchApprove("");
                setOpen(!open);
              }}
              icon={<PlusCircleFilled style={{ color: "white" }} />}
              text="เพิ่มพนักงาน"
            />
          </Col>
        </Row>
        <br />
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: color.navy,
                headerColor: color.white,
              },
            },
          }}
        >
          <Table dataSource={data || []} columns={columns} pagination={false} />
        </ConfigProvider>
        <br />
        <Row justify={"center"}>
          <Pagination
            current={page}
            total={count || 0}
            onChange={(e) => setPage(e)}
            showSizeChanger={false}
            pageSize={8}
          />
        </Row>
        <br />
      </Contrainer>
      {open && (
        <Modal
          title={
            <Text fontWeight={700} fontSize={24}>
              {isEdit ? "แก้ไข" : "เพิ่ม"}พนักงาน
            </Text>
          }
          centered
          open={open}
          footer={false}
          onCancel={() => {
            setOpen(!open);
            form.resetFields();
          }}
          width={500}
        >
          <Form layout="vertical" form={form}>
            <Col span={24}>
              <Form.Item
                label={<Text>ชื่อพนักงาน :</Text>}
                name="employeeId"
                rules={[
                  {
                    required: true,
                    message: "โปรดเลือกรายชื่ือ",
                  },
                ]}
              >
                <Select
                  disabled={isEdit}
                  allowClear
                  showSearch
                  onSearch={(e) => {
                    if (e) {
                      setSearchEmp(e);
                    } else {
                      setSearchEmp("");
                    }
                  }}
                  onChange={(e) => {
                    if (e) {
                      findEmpComId(e);
                      setSearchEmp(searchEmp);
                    } else {
                      setSearchEmp("");
                    }
                  }}
                  options={empList?.map((y: any) => {
                    return {
                      label: (
                        <Row>
                          <Text fontWeight={700}>
                            {y?.firstname +
                              " " +
                              y?.lastname +
                              " (" +
                              COMPAY_MAPPING_DES[`${y.companyId}`] +
                              ")"}
                          </Text>
                        </Row>
                      ),
                      value: y?.employeeId,
                      key: y?.firstname + " " + y?.lastname,
                    };
                  })}
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) => {
                    return (option?.key.toUpperCase() ?? "").includes(
                      input.toUpperCase()
                    );
                  }}
                  filterSort={(optionA: any, optionB: any) => {
                    return (optionA?.key ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.key ?? "").toLowerCase());
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label={<Text>ชื่อผู้ประเมิน :</Text>}
                name="employeeApproveId"
                rules={[
                  {
                    required: true,
                    message: "โปรดเลือกรายชื่ือ",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={(e) => {
                    if (e) {
                      setSearchApprove(e);
                    } else {
                      setSearchApprove("");
                    }
                  }}
                  onChange={(e) => {
                    if (e) {
                      setSearchApprove(searchApprove);
                    } else {
                      setSearchApprove("");
                    }
                  }}
                  options={approveList?.map((y: any) => {
                    return {
                      label: (
                        <Row>
                          <Text fontWeight={700}>
                            {y?.firstname +
                              " " +
                              y?.lastname +
                              " (" +
                              COMPAY_MAPPING_DES[`${y.companyId}`] +
                              ")"}
                          </Text>
                        </Row>
                      ),
                      value: y?.employeeId,
                      key: y?.firstname + " " + y?.lastname,
                    };
                  })}
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) => {
                    return (option?.key.toUpperCase() ?? "").includes(
                      input.toUpperCase()
                    );
                  }}
                  filterSort={(optionA: any, optionB: any) => {
                    return (optionA?.key ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.key ?? "").toLowerCase());
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label={
                  <Text>
                    Acknowledge : *ถ้ามี E-mail มากกว่า 1 กรุณาใส่ลูกน้ำ (,)
                    คั้น*
                  </Text>
                }
                name="acknowledgeEmail"
              >
                <TextArea
                  style={{ fontFamily: "Sarabun", fontWeight: "bold" }}
                  placeholder="ระบุ E-mail"
                  rows={4}
                />
              </Form.Item>
            </Col>
          </Form>
          <Row justify={"end"} gutter={8}>
            <Col>
              <Buttons
                bgColor={color.error}
                onClick={() => {
                  setOpen(!open);
                  form.resetFields();
                }}
                text="ยกเลิก"
              />
            </Col>
            <Col>
              <Buttons
                bgColor={color.navy}
                onClick={() => {
                  submit();
                }}
                text="บันทึก"
              />
            </Col>
          </Row>
        </Modal>
      )}
    </>
  );
};
export default ApprovalEmplyee;
