import { AxiosResponse } from "axios";
import { BASE_URL, httpClient, intanceAuth } from "../config/develop-config";

const baseUrl = `${BASE_URL}`;

export class pmsDatasource {
  static getPMS(params: object) {
    return httpClient
      .get(`${baseUrl}/pms`, { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static createSelfRefect = async (params: object) => {
    return await httpClient
      .post(`${baseUrl}/pms`, params)
      .then((res: AxiosResponse) => res.data)
      .catch((err) => console.log(err));
  };
}
