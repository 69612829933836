export const PeriodList = [
  // { key: 1, month: "January" },
  // { key: 2, month: "February" },
  // { key: 3, month: "March" },
  // { key: 4, month: "April" },
  // { key: 5, month: "May" },
  // { key: 6, month: "June" },
  // { key: 7, month: "July" },
  // { key: 8, month: "August" },
  // { key: 9, month: "September" },
  // { key: 10, month: "October" },
  // { key: 11, month: "November" },
  // { key: 12, month: "December" },
  { key: 13, month: "Mid Year" },
  { key: 14, month: "Final Year" },
];
