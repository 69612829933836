import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Input,
  Select,
  Avatar,
  Radio,
  Divider,
  Space,
  Badge,
  Form,
  ConfigProvider,
} from "antd";
import Text from "../../components/Text/Text";
import color from "../../resource/color";
import Header from "../../components/Header/Header";
import Contrainer from "../../components/Contrainer/Contrainer";
import { Footer } from "antd/lib/layout/layout";
import {
  SCORE_MAPPING,
  SCORE_MAPPING_COLOR,
  SCORE_MAPPING_COLORS,
  SCORE_MAPPING_DES,
} from "../../definitions/score";
import { YearList } from "../../definitions/Years";
import { PeriodList } from "../../definitions/Period";
import { EmployeeApproveDatasource } from "../../datasource/EmploeeApprove";
import { useNavigate } from "react-router-dom";
import { SelfReflcetionDatasource } from "../../datasource/selfReflectionDatasoure";
import { SettingDatasource } from "../../datasource/settingDatasource";
import { GrowthDatasource } from "../../datasource/growthDatasource";
import { useForm } from "antd/lib/form/Form";
import Swal from "sweetalert2";
import { pmsDatasource } from "../../datasource/pmsDatabase";
import { EmployeeDatasource } from "../../datasource/employeeDatasource";
const { TextArea } = Input;

const ManagerPmsReport: React.FC = () => {
  const [form] = useForm();
  const profile = JSON.parse(localStorage.getItem("rainbowProfile")!);
  const resolutionList = [
    {
      key: "pmsStay",
      name: "Stay",
      description:
        "Stay (อะไรบ้างที่เราคิดว่าพนักงานทำได้ดี ควรชื่นชมและอยากให้รักษาสิ่งนี้ไว้)",
    },
    {
      key: "pmsStart",
      name: "Start",
      description:
        "Start (สิ่งที่พนักงานยังไม่ได้ทำ แต่ถ้าเริ่มทำมันจะดีสำหรับพนักงาน)",
    },
    {
      key: "pmsStop",
      name: "Stop",
      description:
        "Stop (สิ่งที่เราเห็นว่าหากพนักงานไม่มีพฤติกรรมนี้ แล้วจะทำให้มีประสิทธิภาพมากขึ้น)",
    },
    {
      key: "pmsCareerPath",
      name: "Career Path",
      description:
        "เส้นทาง ความก้าวหน้าในสายอาชีพ ของพนักงานคนนี้ หรือ สิ่งที่พนักงานคนนี้ควรได้รับการพัฒนา เพื่อความก้าวหน้าในสายงาน",
    },
    {
      key: "pmsOverall",
      name: "Overall",
      description: "ภาพรวม ทั้งหมด หรือ Note  อื่นๆ สำหรับการพูดคุย",
    },
  ];
  const yearList = YearList();
  const [year, setYear] = useState<any>(new Date().getFullYear());
  const [month, setMonth] = useState<number>(
    new Date().getMonth() + 1 >= 6 ? 13 : 14
  );
  const [pms, setPms] = useState<any>([]);
  const [empList, setEmpList] = useState<any>([]);
  const [empSelected, setEmpSelected] = useState<string>();
  const [managerSelected, setManagerSelected] = useState<any>();
  const [selfReflce, setSelfReflce] = useState<any>([]);
  const [needSupport, setNeedSupport] = useState<any>([]);
  const [resolution, setResolution] = useState<any>([]);
  const [overall, setOverall] = useState<any>([]);
  const [checkSubmit, setCheckSubmit] = useState<boolean>(false);
  const [score, setScore] = useState<any>([]);
  const [growthPoten, setGrowthPoten] = useState<any>([]);

  const getPms = async () => {
    const data: any = await pmsDatasource
      .getPMS({
        employeeId: empSelected,
        supervisorEmployeeId: managerSelected?.employeeApproveId,
        pmsYear: year,
        pmsMonth: month,
      })
      .then(async (res) => {
        return res;
      });
    setPms(data.responseData);
    if (data.success) {
      await data?.responseData?.pmsScore?.forEach((s: any) => {
        form.setFieldValue(`score_${s.scoreConditionId}`, s.pmsScoreValue);
        form.setFieldValue(`text_${s.scoreConditionId}`, s.pmsScoreDesc);
      });
      form.setFieldsValue(data.responseData);
    } else {
      setPms({ isSubmit: false });
      form.resetFields();
      form.setFieldsValue({
        employeeId: empSelected,
        supervisorEmployeeId: profile.employeeId,
        pmsYear: year,
        pmsMonth: month,
        updateBy: profile.firstname + " " + profile.lastname,
        isSubmit: false,
      });
    }
  };
  const getEmployee = async () => {
    await EmployeeApproveDatasource.getEmployeeWithLevel(
      profile.employeeId
    ).then((res) => {
      if (res.success) {
        setEmpList(res.responseData.filter((x: any) => x.employee));
      }
    });
  };
  const getSelfReflce = async () => {
    const condition = await SettingDatasource.getSelfCondition(year).then(
      (res) => res
    );
    await SelfReflcetionDatasource.getSelfReflection({
      employeeId: empSelected,
      selfReflectionYear: year,
      selfReflectionMonth: month,
    }).then(async (res) => {
      if (res.length > 0) {
        setCheckSubmit(res[0].isSubmit);
      } else {
        setCheckSubmit(false);
      }
      const mapSelf = await condition.find((x: any) => {
        const filter = x?.sectionOrder === 1;
        if (filter) {
          return x;
        }
        return filter;
      });
      const newMapSelf = await mapSelf?.sectionSubjectDetail?.map(
        (con: any) => {
          const sectionOne = res?.find((x: any) => {
            const filter = x?.selfReflectionSetting?.sectionOrder === 1;
            if (filter) {
              return x;
            }
          })?.selfReflectionValue;
          return {
            ...con,
            value: sectionOne?.find((a: any) => a.key === con.key)?.value || "",
          };
        }
      );
      const mapNeedSupport = await condition.find((x: any) => {
        const filter = x?.sectionOrder === 2;
        if (filter) {
          return x;
        }
        return filter;
      });
      const newMapSupport = await mapNeedSupport?.sectionSubjectDetail?.map(
        (con: any) => {
          const sectionTwo = res?.find((x: any) => {
            const filter = x?.selfReflectionSetting?.sectionOrder === 2;
            if (filter) {
              return x;
            }
          })?.selfReflectionValue;
          return {
            ...con,
            value: sectionTwo?.find((a: any) => a.key === con.key)?.value || "",
          };
        }
      );
      const mapResolution = await condition.find((x: any) => {
        const filter = x?.sectionOrder === 3;
        if (filter) {
          return x;
        }
        return filter;
      });
      const newMapResol = await mapResolution?.sectionSubjectDetail?.map(
        (con: any) => {
          const sectionThree = res?.find((x: any) => {
            const filter = x?.selfReflectionSetting?.sectionOrder === 3;
            if (filter) {
              return x;
            }
          })?.selfReflectionValue;
          return {
            ...con,
            value:
              sectionThree?.find((a: any) => a.key === con.key)?.value || "",
          };
        }
      );
      const mapOverall = await condition.find((x: any) => {
        const filter = x?.sectionOrder === 4;
        if (filter) {
          return x;
        }
        return filter;
      });
      const newMapOverall = await mapOverall?.sectionSubjectDetail?.map(
        (con: any) => {
          const sectionFour = res?.find((x: any) => {
            const filter = x?.selfReflectionSetting?.sectionOrder === 4;
            if (filter) {
              return x;
            }
          })?.selfReflectionValue;
          return {
            ...con,
            value:
              sectionFour?.find((a: any) => a.key === con.key)?.value || "",
          };
        }
      );
      setNeedSupport(newMapSupport);
      setSelfReflce(newMapSelf);
      setResolution(newMapResol);
      setOverall(newMapOverall);
    });
  };
  const getScore = async () => {
    await SettingDatasource.getScoreCondition().then((res) => {
      setScore(res);
    });
  };
  const getGrowthPotential = async () => {
    await GrowthDatasource.getGrowthCondition().then((res) => {
      setGrowthPoten(res);
    });
  };

  useEffect(() => {
    !empSelected && getEmployee();
    if (empSelected && year && month) {
      getPms();
      getSelfReflce();
      getScore();
      getGrowthPotential();
    }
  }, [empSelected, year, month, checkSubmit, managerSelected]);

  const Title = () => {
    return (
      <>
        <Row justify={"space-between"} style={{ padding: "10px" }}>
          <Col span={12}>
            <Row justify={"space-between"}>
              <Col span={12}>
                <Text fontWeight={700} fontSize={20} color="navy">
                  Employee Name :
                </Text>
                <br />
                <Text color="navy">ชื่อพนักงาน</Text>
              </Col>
              <Col span={12}>
                <Select
                  allowClear={empSelected ? true : false}
                  showSearch
                  bordered={!empSelected ? true : false}
                  options={empList?.map((y: any) => {
                    return {
                      label: (
                        <Row justify={"space-around"}>
                          <Text
                            color="darkyellow"
                            fontWeight={700}
                            fontSize={20}
                          >
                            {y?.employee?.firstname +
                              " " +
                              y?.employee?.lastname}
                          </Text>
                        </Row>
                      ),
                      value: y?.employee?.employeeId,
                      key: y?.employee?.firstname + " " + y?.employee?.lastname,
                    };
                  })}
                  virtual={false}
                  style={{
                    width: "95%",
                    height: "80%",
                  }}
                  defaultValue={empSelected}
                  onChange={async (e) => {
                    setEmpSelected(e);
                    const findManager = empList?.find(
                      (x: any) => `${x.employeeId}` === `${e}`
                    );
                    setManagerSelected(
                      await EmployeeDatasource.getEmployee(
                        findManager?.employeeApproveId
                      ).then((res) => {
                        return res.responseData;
                      })
                    );
                  }}
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) => {
                    return (option?.key.toUpperCase() ?? "").includes(
                      input.toUpperCase()
                    );
                  }}
                  filterSort={(optionA: any, optionB: any) => {
                    return (optionA?.key ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.key ?? "").toLowerCase());
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row justify={"space-between"}>
              <Col span={12}>
                <Text fontWeight={700} fontSize={20} color="navy">
                  Supervisor Name :
                </Text>
                <br />
                <Text color="navy">ชื่อผู้บังคับบัญชา</Text>
              </Col>
              <Col span={12}>
                <Row justify={"space-around"}>
                  <Text fontWeight={700} fontSize={20} color="green">
                    {managerSelected
                      ? `${managerSelected?.firstname} ${managerSelected?.lastname}`
                      : `${profile.firstname} ${profile.lastname}`}
                  </Text>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify={"space-between"} style={{ padding: "10px" }}>
          <Col span={12}>
            <Row justify={"space-between"}>
              <Col span={12}>
                <Text fontWeight={700} fontSize={20} color="navy">
                  Position :
                </Text>
                <br />
                <Text color="navy">ตำแหน่ง</Text>
              </Col>
              {empSelected && (
                <Col span={12}>
                  <Row justify={"space-around"}>
                    <Text fontWeight={700} fontSize={20} color="green">
                      {empList?.find((x: any) => x.employeeId === empSelected)
                        .employee.position?.positionName || ""}
                    </Text>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
          <Col span={12}>
            <Row justify={"space-between"}>
              <Col span={6}>
                <Text fontWeight={700} fontSize={20} color="navy">
                  Year :
                </Text>
                <br />
                <Text color="navy">ปี ค.ศ.</Text>
              </Col>
              <Col span={6}>
                <Select
                  bordered={false}
                  onChange={(e) => setYear(e)}
                  style={{ width: "80%", height: "80%" }}
                  defaultValue={year}
                  options={yearList.map((y: any) => {
                    return {
                      label: (
                        <Text color="darkyellow" fontWeight={700}>
                          {y}
                        </Text>
                      ),
                      value: y,
                    };
                  })}
                />
              </Col>
              <Col span={6}>
                <Text fontWeight={700} fontSize={20} color="navy">
                  Types :
                </Text>
                <br />
                <Text color="navy">ประเภท</Text>
              </Col>
              <Col span={6}>
                <Select
                  bordered={false}
                  style={{
                    width: "80%",
                    height: "80%",
                  }}
                  onChange={(e) => setMonth(e)}
                  defaultValue={month}
                  options={PeriodList.map((y) => {
                    return {
                      label: (
                        <Text color="darkyellow" fontWeight={700}>
                          {y.month}
                        </Text>
                      ),
                      value: y.key,
                    };
                  })}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify={"space-between"} style={{ padding: "10px" }}>
          <Col span={12}>
            <Row justify={"space-between"}>
              <Col span={12}>
                <Text fontWeight={700} fontSize={20} color="navy">
                  Job Description (JD) :
                </Text>
                <br />
                <Text color="navy">หน้าที่</Text>
              </Col>
              {profile?.position?.team?.department?.company?.companyId ===
                `1` && (
                <Col span={12}>
                  <Row justify={"space-around"}>
                    <Text
                      fontWeight={700}
                      fontSize={20}
                      color="darkyellow"
                      style={{ cursor: "pointer" }}
                    >
                      <a
                        href="https://icpladda.monday.com/boards/521144129/views/145281099"
                        target="_blank"
                        style={{
                          color: color.darkyellow,
                          textDecoration: "underline",
                        }}
                      >
                        คลิกเพื่อดู JD
                      </a>
                    </Text>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
          <Col span={6}>
            <Row justify={"space-between"}>
              <Col span={12}>
                <Text fontWeight={700} fontSize={20} color="navy">
                  PMS Manual :
                </Text>
                <br />
                <Text color="navy">คู่มือ</Text>
              </Col>
              <Text
                fontWeight={700}
                fontSize={20}
                color="darkyellow"
                style={{ cursor: "pointer" }}
              >
                <a
                  href="https://docs.google.com/presentation/d/15dYi_rxdH_DlukRrU0GO0pof1xKbBqlzna7N56HRpHc/edit?usp=sharing"
                  target="_blank"
                  style={{
                    color: color.darkyellow,
                    textDecoration: "underline",
                  }}
                >
                  คู่มือการทำ PMS
                </a>
              </Text>
            </Row>
          </Col>
          <Col span={empSelected ? 3 : 6} />
          {empSelected && (
            <Col span={3}>
              <Row justify={"space-between"}>
                <Col>
                  <Text
                    fontWeight={700}
                    fontSize={20}
                    color={form.getFieldValue("isSubmit") ? "success" : "error"}
                  >
                    {form.getFieldValue("isSubmit") ? "Submited" : "Processing"}
                  </Text>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </>
    );
  };
  const partOne = (
    <>
      <Header label="ช่วงที่ 1" fontSize={24} color={color.green} />
      <br />
      <Row justify={"center"} gutter={8}>
        <Col>
          <Avatar
            shape="square"
            size="large"
            style={{
              backgroundColor: color.pink,
              borderColor: color.gray,
            }}
          />
          <Text fontWeight={700}>
            {" "}
            พื้นหลังสีชมพูคือ ข้อมูลที่พนักงานส่งเข้ามา
          </Text>
        </Col>
      </Row>
      <br />
      <Header
        label="Self-Reflection ของพนักงาน"
        fontSize={24}
        color={color.green}
      />
      <br />
      <Row
        justify={checkSubmit ? "space-between" : "center"}
        gutter={8}
        style={{ padding: checkSubmit ? "" : "6%" }}
      >
        {checkSubmit ? (
          selfReflce?.map((x: any) => (
            <>
              <Col span={12}>
                <Row justify={"space-around"}>
                  <Col span={18}>
                    <Header
                      label={x?.sectionSubjectTitle}
                      color={color.navy}
                      fontSize={20}
                    />
                  </Col>
                </Row>
                <Card
                  style={{
                    backgroundColor: color.pink,
                  }}
                >
                  <TextArea
                    value={`คำตอบ : ${x?.value}`}
                    bordered={false}
                    autoSize={{ minRows: 7, maxRows: 7 }}
                    style={{
                      fontFamily: "Sarabun",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                  />
                </Card>
                <br />
              </Col>
            </>
          ))
        ) : (
          <Text fontSize={36} color="gray">
            พนักงานยังไม่ Submit การประเมิน
          </Text>
        )}
      </Row>
      <Header
        label="Need Support ของพนักงาน"
        fontSize={24}
        color={color.green}
      />
      <br />
      <Row
        justify={checkSubmit ? "space-between" : "center"}
        gutter={8}
        style={{ padding: checkSubmit ? "" : "6%" }}
      >
        {checkSubmit ? (
          needSupport?.map((x: any) => (
            <>
              <Col span={12}>
                <Row justify={"space-around"}>
                  <Col span={18}>
                    <Header
                      label={x?.sectionSubjectTitle}
                      color={color.navy}
                      fontSize={16}
                    />
                  </Col>
                </Row>
                <Card
                  style={{
                    backgroundColor: color.pink,
                  }}
                >
                  <TextArea
                    value={`คำตอบ : ${x?.value}`}
                    bordered={false}
                    autoSize={{ minRows: 7, maxRows: 7 }}
                    style={{
                      fontFamily: "Sarabun",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                  />
                </Card>
                <br />
              </Col>
            </>
          ))
        ) : (
          <Text fontSize={36} color="gray">
            พนักงานยังไม่ Submit การประเมิน
          </Text>
        )}
      </Row>
    </>
  );
  const partTwo = (
    <>
      <Header label="ช่วงที่ 2" fontSize={24} color={color.green} />
      <br />
      <Row justify={"center"}>
        <Col span={12}>
          <Header
            label="2. บอกผลประเมิน และ What is next"
            color={color.navy}
            fontSize={20}
          />
        </Col>
      </Row>
      <br />
      {score
        .filter((a: any) => a.scoreConditionName === "Final Score")
        .map((x: any) => (
          <>
            <Col span={8}>
              <Header
                label={x?.scoreConditionName}
                color={color.navy}
                fontSize={20}
              />
            </Col>
            <br />
            <Row justify={"space-between"}>
              {x?.scoreConditionDetail?.map((y: any) => (
                <ConfigProvider
                  theme={{
                    components: {
                      Radio: {
                        colorPrimary: SCORE_MAPPING_COLORS[y.value],
                        colorBgContainerDisabled: !y.value
                          ? SCORE_MAPPING_COLORS[y.value]
                          : "",
                      },
                    },
                  }}
                >
                  <Form.Item
                    name={`score_${x.scoreConditionId}`}
                    rules={[
                      {
                        required: true,
                        message: "*โปรดระบุคะแนน",
                      },
                    ]}
                  >
                    <Radio.Group disabled>
                      <Radio
                        value={y.value}
                        style={{ display: "inline-grid", placeItems: "center" }}
                      >
                        <Col style={{ display: "grid", placeItems: "center" }}>
                          <Text
                            color={SCORE_MAPPING_COLOR[y.value]}
                            fontWeight={700}
                            fontSize={20}
                          >
                            {SCORE_MAPPING[y.value]}
                          </Text>
                        </Col>
                        <Col style={{ display: "grid", placeItems: "center" }}>
                          <Text
                            color={SCORE_MAPPING_COLOR[y.value]}
                            fontWeight={700}
                            fontSize={18}
                            style={{
                              display: "inline-grid",
                              placeItems: "center",
                            }}
                          >
                            {SCORE_MAPPING_DES[y.value]}
                          </Text>
                        </Col>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </ConfigProvider>
              ))}
            </Row>
            <Divider style={{ backgroundColor: color.green, padding: "3px" }} />
            <br />
          </>
        ))}
      {score
        .filter((a: any) => a.scoreConditionName !== "Final Score")
        .map((x: any) => (
          <>
            <Col span={8}>
              <Header
                label={x?.scoreConditionName}
                color={color.navy}
                fontSize={20}
              />
            </Col>
            <br />
            <Row justify={"space-between"}>
              {x?.scoreConditionDetail?.map((y: any) => (
                <ConfigProvider
                  theme={{
                    components: {
                      Radio: {
                        colorPrimary: SCORE_MAPPING_COLORS[y.value],
                        colorBgContainerDisabled: !y.value
                          ? SCORE_MAPPING_COLORS[y.value]
                          : "",
                      },
                    },
                  }}
                >
                  <Form.Item
                    name={`score_${x.scoreConditionId}`}
                    rules={[
                      {
                        required: true,
                        message: "*โปรดระบุคะแนน",
                      },
                    ]}
                  >
                    <Radio.Group disabled>
                      <Radio
                        value={y.value}
                        style={{ display: "inline-grid", placeItems: "center" }}
                      >
                        <Col style={{ display: "grid", placeItems: "center" }}>
                          <Text
                            color={SCORE_MAPPING_COLOR[y.value]}
                            fontWeight={700}
                            fontSize={20}
                          >
                            {SCORE_MAPPING[y.value]}
                          </Text>
                        </Col>
                        <Col style={{ display: "grid", placeItems: "center" }}>
                          <Text
                            color={SCORE_MAPPING_COLOR[y.value]}
                            fontWeight={700}
                            fontSize={18}
                            style={{
                              display: "inline-grid",
                              placeItems: "center",
                            }}
                          >
                            {SCORE_MAPPING_DES[y.value]}
                          </Text>
                          <TextArea
                            style={{
                              display: "inline-grid",
                              placeItems: "center",
                              fontFamily: "Sarabun",
                              fontWeight: "bold",
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                            value={y.description}
                            autoSize
                            bordered={false}
                          />
                        </Col>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </ConfigProvider>
              ))}
            </Row>
            <Text fontWeight={700} style={{ padding: "25%" }}>
              จงอธิบายเหตุผลเกณฑ์การให้คะแนนพนักงาน...
            </Text>
            <Row justify={"center"}>
              <Col span={12}>
                <Form.Item
                  name={`text_${x.scoreConditionId}`}
                  rules={[
                    {
                      required: true,
                      message: "*โปรดระบุเหตุผลการให้คะแนน",
                    },
                  ]}
                >
                  <TextArea
                    rows={5}
                    style={{
                      width: "100%",
                      color: color.black,
                      fontFamily: "Sarabun",
                      fontWeight: "bold",
                    }}
                    autoSize={{ minRows: 5, maxRows: 5 }}
                    placeholder="ข้อความ..."
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider style={{ backgroundColor: color.green, padding: "3px" }} />
            <br />
          </>
        ))}
    </>
  );
  const partThree = (
    <>
      <Col span={12}>
        <Header
          label="Growth Potential (ศักยภาพในการเติบโต)"
          color={color.navy}
          fontSize={20}
        />
      </Col>
      <br />

      <Form.Item
        name="growthPotentialId"
        rules={[
          {
            required: true,
            message: (
              <div style={{ paddingLeft: "15%", paddingBottom: "2%" }}>
                * โปรดระบุ Growth Potential
              </div>
            ),
          },
        ]}
      >
        <Radio.Group
          style={{ paddingLeft: "15%", paddingBottom: "2%" }}
          disabled
        >
          <Space direction="vertical">
            {growthPoten?.map((x: any) => (
              <ConfigProvider
                theme={{
                  components: {
                    Radio: {
                      colorBgContainerDisabled:
                        pms?.growthPotentialId === x?.growthPotentialId
                          ? color.navy
                          : "",
                    },
                  },
                }}
              >
                <Radio value={x?.growthPotentialId}>
                  <Text fontWeight={700}>{x?.growthPotentialName}</Text>
                </Radio>
              </ConfigProvider>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  );
  const partFour = (
    <>
      <Header label="Stay Start Stop" fontSize={24} color={color.green} />
      <br />
      {checkSubmit ? (
        resolution.map((x: any) => (
          <Row justify={"center"}>
            <Col span={15}>
              <Row justify={"center"}>
                <Col span={10}>
                  <Header
                    label={x.sectionSubjectTitle}
                    color={color.navy}
                    fontSize={20}
                  />
                </Col>
              </Row>
              <Card
                style={{
                  backgroundColor: color.pink,
                }}
              >
                <TextArea
                  value={`คำตอบ : ${x.value}`}
                  bordered={false}
                  autoSize={{ minRows: 4, maxRows: 4 }}
                  style={{
                    fontFamily: "Sarabun",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                />
              </Card>
              <br />
            </Col>
          </Row>
        ))
      ) : (
        <Row justify={"center"} style={{ padding: "6%" }}>
          <Text fontSize={36} color="gray">
            พนักงานยังไม่ Submit การประเมิน
          </Text>
        </Row>
      )}
      <Header
        label="ความคิดเห็นเพิ่มเติม Overall"
        fontSize={24}
        color={color.green}
      />
      <br />
      {checkSubmit ? (
        overall?.map((x: any) => (
          <Row justify={"center"}>
            <Col span={15}>
              <Row justify={"center"}>
                <Col span={10}>
                  <Header
                    label={x.sectionSubjectTitle}
                    color={color.navy}
                    fontSize={20}
                  />
                </Col>
              </Row>
              <Card
                style={{
                  backgroundColor: color.pink,
                }}
              >
                <TextArea
                  value={`คำตอบ : ${x.value}`}
                  bordered={false}
                  autoSize={{ minRows: 4, maxRows: 4 }}
                  style={{
                    fontFamily: "Sarabun",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                />
              </Card>
              <br />
            </Col>
          </Row>
        ))
      ) : (
        <Row justify={"center"} style={{ padding: "6%" }}>
          <Text fontSize={36} color="gray">
            พนักงานยังไม่ Submit การประเมิน
          </Text>
        </Row>
      )}
      <Divider style={{ backgroundColor: color.green, padding: "3px" }} />
      {resolutionList.map((x) => (
        <Row justify={"space-between"} gutter={16} style={{ padding: "8px" }}>
          <Col span={12}>
            <Badge.Ribbon
              text={
                <Text fontWeight={700} color="white" fontSize={24}>
                  {x.name}
                </Text>
              }
              placement="start"
              color={color.navy}
            >
              <Card
                style={{
                  width: "100%",
                  height: "85%",
                  backgroundColor: "#E1E5F2",
                  padding: "30px",
                }}
              >
                <Text fontWeight={700}>{x.description}</Text>
              </Card>
            </Badge.Ribbon>
          </Col>
          <Col span={12}>
            <Form.Item
              name={x.key}
              rules={[
                {
                  required: true,
                  message: "*โปรดระบุคำตอบ",
                },
              ]}
            >
              <TextArea
                rows={8}
                style={{
                  width: "100%",
                  color: color.black,
                  fontFamily: "Sarabun",
                  fontWeight: "bold",
                }}
                autoSize={{ minRows: 6, maxRows: 6 }}
                placeholder="กรุณาพิมพ์คำตอบที่นี่..."
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
      ))}
      <br />
    </>
  );
  const partFive = (
    <>
      <Header label="ช่วงที่ 3" fontSize={24} color={color.green} />
      <br />
      <Row justify={"center"}>
        <Col span={12}>
          <Header
            label="3. Feedback & Acknowledgement"
            color={color.navy}
            fontSize={20}
          />
        </Col>
      </Row>
      <br />
      <Col style={{ paddingLeft: "9%" }}>
        <Text fontWeight={700}>Note :</Text>
        <Text fontWeight={600} color="black">
          {" "}
          สามารถระบุอะไรก็ได้ที่อยากทำการ Note ไว้ดูย้อนหลัง (ไม่บังคับใส่ข้อมูล
          ไม่มีผลใดๆ เกี่ยวกับการประเมิน)
        </Text>
      </Col>
      <Row justify={"center"}>
        <Col span={20}>
          <Form.Item name="pmsFeedback">
            <TextArea
              rows={5}
              style={{
                width: "100%",
                color: color.black,
                fontFamily: "Sarabun",
                fontWeight: "bold",
              }}
              placeholder="ข้อความ..."
              disabled
              autoSize={{ minRows: 5, maxRows: 5 }}
            />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row justify={"center"} style={{ padding: "20px" }}>
        <Space direction="vertical">
          <ConfigProvider
            theme={{
              components: {
                Checkbox: {
                  colorBgContainerDisabled: pms?.isEmployeeFeedback
                    ? color.navy
                    : "",
                },
              },
            }}
          >
            <Form.Item
              noStyle
              name="isEmployeeFeedback"
              valuePropName="checked"
            >
              <Checkbox onChange={onSaveResult} disabled>
                <Text fontWeight={700}>
                  พนักงานได้มีการให้คำแนะนำ Feedback กับคุณเรียบร้อยแล้ว
                </Text>
              </Checkbox>
            </Form.Item>
          </ConfigProvider>
          <ConfigProvider
            theme={{
              components: {
                Checkbox: {
                  colorBgContainerDisabled: pms?.isGivePraise ? color.navy : "",
                },
              },
            }}
          >
            <Form.Item name="isGivePraise" noStyle valuePropName="checked">
              <Checkbox onChange={onSaveResult} disabled>
                <Text fontWeight={700}>
                  คุณได้ให้คำชื่นชมกับพนักงานเรียบร้อยแล้ว
                </Text>
              </Checkbox>
            </Form.Item>
          </ConfigProvider>
        </Space>
      </Row> */}
    </>
  );

  return (
    <>
      <Contrainer>
        <Header
          label="PMS Report Form"
          fontSize={30}
          color={color.navy}
          headerHeight={"60Px"}
        />
        {Title()}
        {empSelected ? (
          <Form form={form}>
            {partOne}
            {partTwo}
            {partThree}
            {partFour}
            {partFive}
          </Form>
        ) : (
          <Row justify={"center"} style={{ paddingTop: "10%" }}>
            <Text fontSize={40} color="gray">
              กรุณาเลือกพนักงาน
            </Text>
          </Row>
        )}
      </Contrainer>
      <Footer style={{ backgroundColor: color.white }} />
    </>
  );
};
export default ManagerPmsReport;
