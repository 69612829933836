import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  HistoryOutlined,
  LeftOutlined,
  PlusOutlined,
  WarningFilled,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Switch,
  Divider,
  Tabs,
  Input,
  Badge,
  Button,
  ConfigProvider,
  Select,
  Tag,
  Avatar,
  Empty,
  Modal,
} from "antd";
import Ribbon from "antd/es/badge/Ribbon";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Buttons from "../../components/Button/Button";
import Contrainer from "../../components/Contrainer/Contrainer";
import Text from "../../components/Text/Text";
import { categotyKciDatasource } from "../../datasource/categotyKciDatasource";
import { EmployeeApproveDatasource } from "../../datasource/EmploeeApprove";
import { kciDatasource } from "../../datasource/kciDatasource";
import {
  KCI_EDIT_STATUS,
  KCI_STATUS_COLOR_MAPPING,
} from "../../definitions/KciStatus";
import color from "../../resource/color";

const CreateKci: React.FC = () => {
  const navigate = useNavigate();
  const profile = JSON.parse(localStorage.getItem("rainbowProfile")!);
  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;

  const empId = pathSplit[3] || 0;
  const year = pathSplit[2] || 0;

  const [search, setSearch] = useState<boolean>(false);
  const [tab, setTab] = useState<any>("Full Year");
  const [category, setCategory] = useState<any>();
  const [searchKciData, setSearchKciData] = useState<any>();
  const [deleteKci, setDeleteKci] = useState<any>([]);
  const [deleteKciYear, setDeleteKciYear] = useState<any>([]);
  const [empList, setEmpList] = useState<any>();
  const [filterEmp, setFilterEmp] = useState<any>(undefined);
  const [selectedEmp, setSelectedEmp] = useState<any>([]);
  const [selectedYear, setSelectedYear] = useState<Number>(Number(year));
  const [selectedCate, setSelectedCate] = useState<any>([]);

  const [modalDelete, setModalDelete] = useState<boolean>(false);

  const status = [
    { label: "Full Year", color: "" },
    { label: "Q1", color: "" },
    { label: "Q2", color: "" },
    { label: "Q3", color: "" },
    { label: "Q4", color: "" },
  ];

  const [data, setData] = useState<any>(
    status.map((x) => ({
      kciDescription: "",
      categoryId: undefined,
      target: x.label,
      years: year,
      actual: 0,
      weight: 0,
      success: 0,
      employeeId: profile.employeeId,
      orderData: 1,
      kciStatus: "",
      isValiDes: false,
      isValiCate: false,
      isValiImpor: false,
    }))
  );
  const [preData, setPreData] = useState<any>();

  const getKciByEmpId = async () => {
    await kciDatasource
      .getKci({
        years: year,
        employeeId: empId,
      })
      .then(async (res) => {
        let map: any = [];
        for (let i = 0; status.length > i; i++) {
          const find = res.data.filter(
            (x: any) => x.target === status[i].label
          );
          if (find.length > 0) {
            await find.forEach((e: any, j: number) => {
              map.push({
                kciId: e.kciId,
                kciDescription: e.kciDescription,
                categoryId: e.categoryId,
                target: e.target,
                years: e.years,
                actual: e.actual,
                weight: e.weight,
                success: e.success,
                employeeId: empId,
                orderData: j + 1,
                kciStatus: e.kciStatus,
                isValiDes: false,
                isValiCate: false,
                isValiImpor: false,
              });
            });
          } else {
            map.push({
              kciDescription: "",
              categoryId: undefined,
              target: status[i].label,
              years: year,
              actual: 0,
              weight: 0,
              success: 0,
              employeeId: profile.employeeId,
              orderData: 1,
              kciStatus: "",
              isValiDes: false,
              isValiCate: false,
              isValiImpor: false,
            });
          }
        }
        setPreData(map);
        setData(map);
      });
  };

  const getCategyKci = async () => {
    await categotyKciDatasource.getCategory().then((res) => {
      setCategory(res.responseData);
    });
  };

  const getAllApprove = async () => {
    await EmployeeApproveDatasource.getAllEmployee({
      page: 1,
      take: 10,
      search: filterEmp,
      companyId: profile.companyId,
      isActive: true,
    }).then((res) => {
      setEmpList(res.responseData);
    });
  };

  useEffect(() => {
    getCategyKci();
    if (empId > 0) {
      getKciByEmpId();
    }
    getAllApprove();
  }, [filterEmp]);

  useEffect(() => {
    if (selectedEmp.length > 0) {
      kciDatasource
        .getKci({
          years: selectedYear,
          employeeId: selectedEmp,
          target: tab ? tab : undefined,
          categoryId: selectedCate,
        })
        .then(async (res) => {
          console.log("search", res);
          setSearchKciData(res.data);
        });
    } else {
      setSearchKciData([]);
    }
  }, [selectedEmp, tab, selectedYear, selectedCate]);

  const pageTtile = () => {
    return (
      <>
        <Row gutter={8}>
          <Col>
            <Text
              level={6}
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer" }}
            >
              หน้าหลัก
            </Text>
          </Col>
          <Col>
            <Text level={6}>/</Text>
          </Col>
          <Col>
            <Text level={6}>
              {empId > 0 ? "แก้ไขรายการของฉัน" : "สร้างรายการใหม่ของฉัน"}{" "}
            </Text>
          </Col>
        </Row>
        <br />
        <Row gutter={8} justify={"space-between"}>
          <Col onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
            <LeftOutlined style={{ fontSize: "20px", paddingTop: "4px" }} />
          </Col>
          <Col span={19}>
            <Text level={3} fontWeight={700}>
              {empId > 0 ? "แก้ไขรายการของฉัน" : "สร้างรายการใหม่ของฉัน"}
            </Text>
          </Col>
          <Col span={4}>
            <Row gutter={8}>
              <Col style={{ paddingTop: "4px" }}>
                <Text level={5} fontWeight={600}>
                  เทียบ KCI กับบุคคลอื่น
                </Text>
              </Col>
              <Col>
                <div style={{ paddingTop: "4px" }}>
                  <Switch
                    style={{ backgroundColor: search ? "#00C875" : "#4A4B4F" }}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={search}
                    onChange={(e) => {
                      setSearch(!search);
                      if (!e) {
                        setSelectedEmp([]);
                        setSelectedCate(undefined);
                      }
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
      </>
    );
  };

  const addKci = () => {
    const checkTab = data.filter((x: any) => x.target === tab).length + 1 > 10;
    if (!checkTab) {
      setData([
        ...data,
        {
          kciDescription: "",
          categoryId: undefined,
          target: tab,
          years: year,
          actual: 0,
          weight: 0,
          success: 0,
          employeeId: profile.employeeId,
          orderData: data.filter((x: any) => x.target === tab).length + 1,
          kciStatus: "DARFT",
          isValiDes: false,
          isValiCate: false,
          isValiImpor: false,
        },
      ]);
    }
  };

  const onChangeValue = (e: any, t: string, name: string, i?: number) => {
    let payload = data;
    if (name === "des") {
      payload = payload.map((x: any) => {
        const map = x.orderData === i && x.target === t;
        if (map) {
          return {
            ...x,
            kciDescription: e,
            isValiDes: false,
            kciStatus: "DRAFT",
          };
        } else {
          return { ...x };
        }
      });
    } else if (name === "category") {
      payload = payload.map((x: any) => {
        const map = x.orderData === i && x.target === t;
        if (map) {
          return {
            ...x,
            categoryId: e,
            isValiCate: false,
            kciStatus: "DRAFT",
          };
        } else {
          return { ...x };
        }
      });
    } else if (name === "importance") {
      const reg = /^-?\d*(\.\d*)?$/;
      payload = payload.map((x: any) => {
        const map = x.orderData === i && x.target === t;
        if (map) {
          if (reg.test(e) || e === "" || e === "-") {
            return {
              ...x,
              weight: e,
              isValiImpor: false,
              kciStatus: "DRAFT",
            };
          } else {
            return {
              ...x,
            };
          }
        } else {
          return { ...x };
        }
      });
    }
    setData(payload);
  };

  const removeRow = async (
    t: string,
    i: number,
    kciId?: string,
    status?: string
  ) => {
    if (kciId) {
      let kciIdList: any = [];
      let kciIdFullYear: any = [];
      if (tab.includes("Year") && status?.includes("APPROVE")) {
        kciIdFullYear.push(...deleteKciYear, kciId);
        setDeleteKciYear(kciIdFullYear);
      } else {
        kciIdList.push(...deleteKci, kciId);
        setDeleteKci(kciIdList);
      }
    }
    let payload = data;
    let row: number = 0;
    const filterData = payload
      .filter((x: any) => x.target !== t || x.orderData !== i + 1)
      .map((y: any) => {
        const find = y.target === t;
        if (find) {
          row = row + 1;
          return { ...y, orderData: row };
        } else {
          return { ...y };
        }
      });
    setData(filterData);
  };

  const save = async (action: string) => {
    let payload = data;
    if (action === "draft") {
      payload = payload.filter(
        (x: any) => x.categoryId || x.kciDescription || x.weight
      );
    }
    payload = payload.map((e: any, i: number) => {
      let findYear = e.target.includes("Full");
      if (findYear) {
        return {
          ...e,
          isValiDes: !e.kciDescription ? true : false,
          isValiCate: !e.categoryId ? true : false,
          isValiImpor: !e.weight ? true : false,
        };
      } else {
        return {
          ...e,
          isValiCate: !e.categoryId && e.kciDescription ? true : false,
          kciStatus: action === "submit" ? "APPROVE" : e.kciStatus,
        };
      }
    });
    setData(payload);
    const checkValidate = payload.filter(
      (x: any) => x.isValiCate || x.isValiDes || x.isValiImpor
    );
    if (checkValidate.length > 0) {
      setTab("Full Year");
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "กรุณากรอกข้อมูลให้ครบ",
        showConfirmButton: true,
        width: 360,
      }).then((value) => {
        if (value.isConfirmed) {
          payload = payload.map((e: any, i: number) => {
            return {
              ...e,
              kciStatus: e.kciStatus,
            };
          });
          setData(payload);
        }
      });
    } else {
      payload = payload.map((e: any, i: number) => {
        if (e.kciId) {
          return {
            ...e,
            updateBy: `${profile.firstname} ${profile.lastname}`,
            kciStatus:
              action === "submit" && e.target.includes("Full")
                ? e.kciStatus.includes("DRAFT") ||
                  e.kciStatus.includes("REJECT")
                  ? "WAIT_APPROVE"
                  : e.kciStatus
                : e.kciStatus,
          };
        } else {
          return {
            ...e,
            createBy: `${profile.firstname} ${profile.lastname}`,
            kciStatus:
              action === "submit" && e.target.includes("Full")
                ? e.kciStatus.includes("DRAFT") ||
                  e.kciStatus.includes("REJECT")
                  ? "WAIT_APPROVE"
                  : e.kciStatus
                : e.kciStatus,
          };
        }
      });
      payload = payload.filter((x: any) => x.categoryId);
      setData(payload);
      if (deleteKci.length > 0) {
        await kciDatasource
          .deleteKci({
            kciId: deleteKci,
            isApproveDelete: true,
            isCancelApproveDelete: false,
          })
          .then((res) => {
            return res;
          });
      }
      if (deleteKciYear.length > 0) {
        let payloadReqDelete: any = [];
        deleteKciYear.map(async (x: any) => {
          const find = preData.find((y: any) => `${y.kciId}` === `${x}`);
          payloadReqDelete.push({
            kciId: find.kciId,
            kciDescription: find.kciDescription,
            categoryId: find.categoryId,
            target: find.target,
            years: find.years,
            actual: find.actual,
            weight: find.weight,
            success: find.success,
            employeeId: find.employeeId,
            updateBy: `${profile.firstname} ${profile.lastname}`,
            //isCancelDelete: false,
            kciStatus: "WAIT_APPROVE_DELETE",
          });
        });
        await kciDatasource.updateKci(payloadReqDelete).then((res) => res);
      }
      if (empId > 0) {
        await kciDatasource.updateKci(payload).then((res) => {
          if (res.success) {
            Swal.fire({
              position: "center",
              icon: "success",
              title:
                action === "draft"
                  ? "บันทึกแบบร่างสำเร็จ"
                  : "ยืนยันการส่งคำขออนุมัติสำเร็จ",
              showConfirmButton: true,
              width: 360,
            }).then((value) => {
              if (value.isConfirmed) {
                navigate(-1);
              }
            });
          }
        });
      } else {
        await kciDatasource.createKci(payload).then((res) => {
          if (res.success) {
            Swal.fire({
              position: "center",
              icon: "success",
              title:
                action === "draft"
                  ? "บันทึกแบบร่างสำเร็จ"
                  : "ยืนยันการส่งคำขออนุมัติสำเร็จ",
              showConfirmButton: true,
              width: 400,
            }).then((value) => {
              if (value.isConfirmed) {
                navigate(-1);
              }
            });
          }
        });
      }
    }
  };

  return (
    <>
      <Contrainer>
        {pageTtile()}
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                //cardBg: color.navy,
                //itemSelectedColor: color.navy,
              },
            },
          }}
        >
          <Tabs
            size="middle"
            //defaultValue={tab}
            defaultActiveKey={tab}
            type="card"
            onChange={(key) => {
              setTab(key);
            }}
            items={status.map((s: any, i: any) => {
              return {
                label: (
                  <>
                    <Text
                      level={6}
                      color={tab === s.label ? "navy" : "black"}
                      fontWeight={700}
                    >
                      {s.label}
                    </Text>
                  </>
                ),
                key: s.label,
                children: (
                  <>
                    <div
                      style={{
                        backgroundColor: color.navy,
                        borderRadius: "8px",
                        height: "40px",
                        padding: "9px",
                      }}
                    >
                      <Row justify={"space-around"} gutter={8}>
                        <Col span={16}>
                          <Text color="white" fontWeight={700} fontSize={16}>
                            คำอธิบาย{" "}
                            <span style={{ color: color.error }}>*</span>
                          </Text>
                        </Col>
                        <Col span={4}>
                          <Text color="white" fontWeight={700} fontSize={16}>
                            หมวดหมู่{" "}
                            <span style={{ color: color.error }}>*</span>
                          </Text>
                        </Col>
                        <Col span={4}>
                          <Text color="white" fontWeight={700} fontSize={16}>
                            Importance{" "}
                            <span style={{ color: color.error }}>*</span>
                          </Text>
                        </Col>
                      </Row>
                    </div>
                    <div
                      style={{
                        paddingTop: "8px",
                      }}
                    />
                    <div
                      style={{
                        backgroundColor: "#E8E9F8",
                        borderTopRightRadius: "5px",
                        borderTopLeftRadius: "5px",
                        height: "40px",
                        padding: "8px",
                      }}
                      key={s.label}
                    >
                      <Row justify={"space-around"} gutter={8}>
                        <Col span={20}>
                          <Text color="navy" fontSize={14}>
                            ทั้งหมด{" "}
                            {
                              data.filter((x: any) => x.target === s.label)
                                .length
                            }{" "}
                            / 10 รายการ
                          </Text>
                        </Col>
                        <Col span={4}>
                          <Text color="navy" fontSize={14}>
                            รวม <b>Importance</b>{" "}
                            {data
                              .filter((a: any) => a.target === s.label)
                              .map((x: any) => {
                                return Number(x.weight);
                              })
                              .reduce((acc: any, curr: any) => {
                                return acc + curr;
                              }, 0)}{" "}
                            %
                          </Text>
                        </Col>
                      </Row>
                    </div>
                    <div
                      style={{
                        height:
                          data?.filter((a: any) => a.target === s.label)
                            .length < 5
                            ? `${data?.filter((a: any) => a.target === s.label)
                                .length} * 63px`
                            : "270px",
                        overflowY: "scroll",
                      }}
                    >
                      {data
                        ?.filter((a: any) => `${a.target}` === `${s.label}`)
                        ?.map((x: any, i: any) => (
                          <div
                            style={{
                              backgroundColor: "#F4F4F6",
                              height: "65px",
                              padding: "8px",
                            }}
                          >
                            <Row justify={"space-around"} gutter={8}>
                              <Col
                                span={1}
                                style={{
                                  paddingTop: "6px",
                                  paddingLeft: "20px",
                                }}
                              >
                                <Badge count={i + 1} color={color.navy} />
                              </Col>
                              <Col span={15}>
                                <Row>
                                  {empId > 0 && x.kciStatus && (
                                    <Col span={1}>
                                      <ConfigProvider
                                        theme={{
                                          token: {
                                            fontSize: 25,
                                          },
                                        }}
                                      >
                                        <Badge
                                          color={
                                            KCI_STATUS_COLOR_MAPPING[
                                              x.kciStatus
                                            ]
                                          }
                                        />
                                      </ConfigProvider>
                                    </Col>
                                  )}
                                  <Col
                                    span={empId > 0 && x.kciStatus ? 23 : 24}
                                  >
                                    {x.kciStatus.includes("DELETE") ? (
                                      <Ribbon
                                        text={
                                          <Text
                                            fontSize={12}
                                            fontWeight={500}
                                            color="white"
                                          >
                                            <HistoryOutlined /> รออนุมัติการลบ
                                          </Text>
                                        }
                                        placement="end"
                                        color={"#F79626"}
                                      >
                                        <Input
                                          disabled={true}
                                          style={{
                                            backgroundColor: color.lightgray,
                                            height: "40px",
                                            borderColor: color.error,
                                            color: color.black,
                                          }}
                                          bordered={x.isValiDes}
                                          value={x.kciDescription}
                                        />
                                      </Ribbon>
                                    ) : (
                                      <Input
                                        disabled={KCI_EDIT_STATUS[x.kciStatus]}
                                        style={{
                                          backgroundColor: KCI_EDIT_STATUS[
                                            x.kciStatus
                                          ]
                                            ? color.lightgray
                                            : color.white,
                                          height: "40px",
                                          borderColor: color.error,
                                          color: color.black,
                                        }}
                                        placeholder="กรอกคำอธิบาย"
                                        bordered={x.isValiDes}
                                        value={x.kciDescription}
                                        autoComplete="off"
                                        onChange={(e: any) =>
                                          onChangeValue(
                                            e.target.value,
                                            s.label,
                                            "des",
                                            i + 1
                                          )
                                        }
                                      />
                                    )}
                                  </Col>
                                  {x.isValiDes && (
                                    <div
                                      style={{
                                        width: "100%",
                                        paddingLeft: "40px",
                                      }}
                                    >
                                      <WarningFilled
                                        style={{ color: color.error }}
                                      />{" "}
                                      <Text color="error" level={6}>
                                        กรุณากรอกคำอธิบาย
                                      </Text>
                                    </div>
                                  )}
                                </Row>
                              </Col>
                              <Col span={4}>
                                <Select
                                  placeholder="เลือกหมวดหมู่"
                                  disabled={KCI_EDIT_STATUS[x.kciStatus]}
                                  style={{
                                    backgroundColor: KCI_EDIT_STATUS[
                                      x.kciStatus
                                    ]
                                      ? color.lightgray
                                      : color.white,
                                    height: "40px",
                                    width: "100%",
                                    color: color.black,
                                  }}
                                  status={x.isValiCate ? "error" : ""}
                                  value={x.categoryId}
                                  bordered={x.isValiCate}
                                  options={category?.map((c: any) => {
                                    return {
                                      label: (
                                        <div
                                          style={{
                                            backgroundColor: c.colorCode,
                                            borderRadius: "5px",
                                            paddingLeft: "5px",
                                          }}
                                        >
                                          <Text
                                            level={6}
                                            color="white"
                                            fontWeight={500}
                                          >
                                            {c.categoryName}
                                          </Text>
                                        </div>
                                      ),
                                      value: c.categoryId,
                                      key: c.categoryId,
                                    };
                                  })}
                                  onChange={(e: any) =>
                                    onChangeValue(e, s.label, "category", i + 1)
                                  }
                                />
                                {x.isValiCate && (
                                  <div
                                    style={{
                                      width: "100%",
                                    }}
                                  >
                                    <WarningFilled
                                      style={{ color: color.error }}
                                    />{" "}
                                    <Text color="error" level={6}>
                                      กรุณาเลือกหมวดหมู่
                                    </Text>
                                  </div>
                                )}
                              </Col>
                              <Col span={3}>
                                <Input
                                  disabled={KCI_EDIT_STATUS[x.kciStatus]}
                                  style={{
                                    color: color.black,
                                    backgroundColor: KCI_EDIT_STATUS[
                                      x.kciStatus
                                    ]
                                      ? color.lightgray
                                      : color.white,
                                    height: "40px",
                                    borderColor: color.error,
                                  }}
                                  suffix="%"
                                  value={x.weight}
                                  placeholder="Importance"
                                  bordered={x.isValiImpor}
                                  autoComplete="off"
                                  onChange={(e: any) =>
                                    onChangeValue(
                                      e.target.value,
                                      s.label,
                                      "importance",
                                      i + 1
                                    )
                                  }
                                />
                                {x.isValiImpor && (
                                  <div style={{ width: "100%" }}>
                                    <WarningFilled
                                      style={{ color: color.error }}
                                    />{" "}
                                    <Text color="error" level={6}>
                                      กรุณากรอก Importance
                                    </Text>
                                  </div>
                                )}
                              </Col>
                              <Col span={1}>
                                {(data.length > 0 || i > 0) &&
                                  !KCI_EDIT_STATUS[x.kciStatus] &&
                                  (empId ? (
                                    <DeleteOutlined
                                      style={{
                                        fontSize: "16px",
                                        paddingTop: "12px",
                                        cursor: "pointer",
                                        color: color.error,
                                      }}
                                      onClick={() => {
                                        setModalDelete(true);
                                        removeRow(
                                          s.label,
                                          i,
                                          x.kciId,
                                          x.kciStatus
                                        );
                                      }}
                                    />
                                  ) : (
                                    <CloseOutlined
                                      style={{
                                        fontSize: "16px",
                                        paddingTop: "12px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setModalDelete(true);
                                        removeRow(
                                          s.label,
                                          i,
                                          undefined,
                                          x.kciStatus
                                        );
                                      }}
                                    />
                                  ))}
                              </Col>
                            </Row>
                          </div>
                        ))}
                      <div
                        style={{
                          backgroundColor: "#F4F4F6",
                          height: "55px",
                          padding: "8px",
                        }}
                      >
                        <Button
                          type="dashed"
                          style={{
                            width: "100%",
                            height: "100%",
                            borderColor: color.navy,
                            backgroundColor: "#F4F4F6",
                          }}
                          onClick={() => addKci()}
                        >
                          <PlusOutlined
                            style={{ fontSize: "20px", color: color.navy }}
                          />
                          <Text fontSize={20} fontWeight={700} color="navy">
                            เพิ่มรายการใหม่
                          </Text>
                        </Button>
                      </div>
                    </div>
                  </>
                ),
              };
            })}
          />
        </ConfigProvider>
        <br />
        {search && (
          <div
            style={{
              border: "solid",
              borderRadius: "8px",
              borderColor: "#F3F4FB",
              padding: "15px",
            }}
          >
            <Row justify={"space-between"} gutter={8}>
              <Col span={10}>
                <Text level={4} fontWeight={700}>
                  เทียบ KCI บุคคลอื่น
                </Text>
              </Col>
              <Col span={2}>
                <Select
                  style={{
                    backgroundColor: color.white,
                    height: "40px",
                    width: "100%",
                  }}
                  value={selectedYear}
                  placeholder="ค้นหาปี"
                  bordered={true}
                  onChange={(e) => setSelectedYear(e)}
                  options={[
                    {
                      key: 2024,
                      label: 2024,
                      value: 2024,
                    },
                    {
                      key: 2025,
                      label: 2025,
                      value: 2025,
                    },
                  ]}
                />
              </Col>
              <Col span={8}>
                <Select
                  placeholder="ค้นหาชื่อ/ตำแหน่ง"
                  style={{ width: "100%", height: "100%" }}
                  allowClear
                  showSearch
                  filterOption={false}
                  onSearch={(e) => {
                    if (e) {
                      setFilterEmp(e);
                    } else {
                      setFilterEmp(undefined);
                    }
                  }}
                  onSelect={(e) => {
                    setFilterEmp(undefined);
                    setSelectedEmp([e]);
                  }}
                  onClear={() => {
                    setFilterEmp(undefined);
                    setSelectedEmp([]);
                  }}
                  options={empList?.map((y: any) => {
                    return {
                      label: (
                        <Row gutter={8} justify="start">
                          <Col>
                            {y?.imagePath ? (
                              <Avatar src={y?.imagePath} />
                            ) : (
                              <Avatar>
                                {y?.firstname.substring(0, 1) || ""}
                                {y?.lastname.substring(0, 1) || ""}
                              </Avatar>
                            )}
                          </Col>
                          <Col>
                            <Text fontWeight={700}>
                              {y?.firstname + " " + y?.lastname}/
                              {y?.position.positionName}
                            </Text>
                          </Col>
                        </Row>
                      ),
                      value: y?.employeeId,
                      key: y?.firstname + " " + y?.lastname,
                    };
                  })}
                />
              </Col>
              <Col span={4}>
                <Select
                  allowClear
                  style={{
                    backgroundColor: color.white,
                    height: "40px",
                    width: "100%",
                  }}
                  placeholder="ค้นหาหมวดหมู่"
                  bordered={true}
                  options={category?.map((c: any) => {
                    return {
                      label: (
                        <div
                          style={{
                            backgroundColor: c.colorCode,
                            borderRadius: "5px",
                            paddingLeft: "5px",
                          }}
                        >
                          <Text level={6} color="white">
                            {c.categoryName}
                          </Text>
                        </div>
                      ),
                      value: c.categoryId,
                      key: c.categoryId,
                    };
                  })}
                  onChange={(e: any) => {
                    if (e) {
                      setSelectedCate(e);
                    } else {
                      setSelectedCate([]);
                    }
                  }}
                />
              </Col>
            </Row>
            <Tabs
              size="middle"
              type="card"
              onChange={(key) => setTab(key)}
              items={status.map((s: any, i: any) => {
                return {
                  label: (
                    <>
                      <Text level={6} color="navy" fontWeight={700}>
                        {s.label}
                      </Text>
                    </>
                  ),
                  key: s.label,
                  children: (
                    <>
                      {searchKciData?.length > 0 ? (
                        <>
                          <div
                            style={{
                              backgroundColor: color.navy,
                              borderTopRightRadius: "8px",
                              borderTopLeftRadius: "8px",
                              height: "40px",
                              padding: "9px",
                            }}
                          >
                            <Row justify={"space-around"} gutter={8}>
                              <Col span={16}>
                                <Text
                                  color="white"
                                  fontWeight={700}
                                  fontSize={16}
                                >
                                  คำอธิบาย
                                </Text>
                              </Col>
                              <Col span={4}>
                                <Text
                                  color="white"
                                  fontWeight={700}
                                  fontSize={16}
                                >
                                  หมวดหมู่
                                </Text>
                              </Col>
                              <Col span={4}>
                                <Text
                                  color="white"
                                  fontWeight={700}
                                  fontSize={16}
                                >
                                  Importance
                                </Text>
                              </Col>
                            </Row>
                          </div>
                          <div
                            style={{
                              height: "200px",
                              overflowY: "scroll",
                            }}
                          >
                            <div
                              style={{
                                height:
                                  searchKciData?.filter(
                                    (a: any) => a.target === s.label
                                  ).length < 5
                                    ? `${searchKciData?.filter(
                                        (a: any) => a.target === s.label
                                      ).length} * 63px`
                                    : "270px",
                                overflowY: "scroll",
                              }}
                            >
                              <>
                                {searchKciData?.map((x: any, i: any) => (
                                  <div
                                    style={{
                                      backgroundColor: "#F4F4F6",
                                      height: "55px",
                                      padding: "8px",
                                    }}
                                  >
                                    <Row justify={"space-around"} gutter={8}>
                                      <Col span={13}>
                                        <Text>
                                          {i + 1}. {x?.kciDescription}
                                        </Text>
                                      </Col>
                                      <Col span={5}>
                                        <Tag
                                          color={x?.category?.colorCode}
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                          }}
                                        >
                                          <Row justify={"center"}>
                                            <Text color="white">
                                              {x?.category?.categoryName}
                                            </Text>
                                          </Row>
                                        </Tag>
                                      </Col>
                                      <Col span={3}>
                                        <Text>{x?.weight} %</Text>
                                      </Col>
                                    </Row>
                                  </div>
                                ))}
                              </>
                            </div>
                          </div>
                        </>
                      ) : (
                        <Empty
                          description={
                            <Text level={6} color="gray">
                              โปรดเลือกบุคคลที่ต้องการเทียบ KCI
                            </Text>
                          }
                        />
                      )}
                    </>
                  ),
                };
              })}
            />
          </div>
        )}
        <br />
        <Row justify={"end"} gutter={8}>
          <Col>
            <Button
              style={{
                backgroundColor: "#F4F6F4",
                borderColor: "#F4F6F4",
                height: "40px",
              }}
              onClick={() => save("draft")}
            >
              <Text color="black" fontWeight={700}>
                บันทึกแบบร่าง
              </Text>
            </Button>
          </Col>
          <Col>
            <Button
              style={{ backgroundColor: color.navy, height: "40px" }}
              onClick={() => save("submit")}
            >
              <Text color="white" fontWeight={700}>
                ส่งคำขออนุมัติ
              </Text>
            </Button>
          </Col>
        </Row>
        <br />
      </Contrainer>
      {modalDelete && (
        <Modal
          title={
            <>
              <Text fontWeight={700} level={2}>
                การลบรายการ KCI Full Year ที่อนุมัติไปเเล้ว
                ต้องรอหัวหน้าพิจารณาการอนุมัติคำขอ
              </Text>
              <Divider />
            </>
          }
          centered
          open={modalDelete}
          //onCancel={() => setModalDelete(!modalDelete)}
          footer={() => (
            <>
              {/* <Buttons text="ยกเลิก" bgColor={color.white} textColor="black" /> */}
              <Buttons
                text="รับทราบ"
                onClick={() => setModalDelete(!modalDelete)}
                bgColor={color.navy}
              />
            </>
          )}
        >
          <Text>
            โปรดตรวจสอบรายละเอียด KCI ที่เลือกและปรึกษาหัวหน้าก่อนกด
            'ส่งคำขออนุมัติ' หากได้รับการอนุมัติแล้ว
            จะไม่สามารถกู้คืนรายการนี้ได้
          </Text>
        </Modal>
      )}
    </>
  );
};

export default CreateKci;
