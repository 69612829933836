import { AxiosResponse } from "axios";
import { BASE_URL, httpClient, intanceAuth } from "../config/develop-config";

const baseUrl = `${BASE_URL}`;

export class GrowthDatasource {
  static getGrowthCondition() {
    return httpClient
      .get(`${baseUrl}/growth-potential`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
