import { DeleteFilled, EditFilled, EditOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Form } from "antd";
import { Header } from "antd/lib/layout/layout";
import Text from "../../components/Text/Text";
import colors from "../../resource/color";

export interface HeaderInputProp {
  label: string;
  color?: string;
  fontSize?:
    | 12
    | 14
    | 16
    | 18
    | 20
    | 22
    | 24
    | 26
    | 28
    | 30
    | 32
    | 34
    | 36
    | 38
    | 40
    | 46
    | 50;
  headerHeight?: "50px" | "60Px";
  align?: "start" | "center" | "end";
  showButtonDelete?: boolean;
  onClickDelete?: () => void;
  style?: React.CSSProperties;
  isEdit?: boolean;
  onChangeInput?: (e: any) => void;
}
export default function HeaderInput({
  label,
  color,
  fontSize,
  headerHeight,
  align,
  showButtonDelete,
  onClickDelete,
  isEdit,
  onChangeInput,
}: HeaderInputProp) {
  return (
    <Header
      style={{
        backgroundColor: color,
        //height: headerHeight,
        borderRadius: "8px",
      }}
    >
      <Row justify={align || "center"} gutter={8}>
        <Col span={23}>
          {isEdit ? (
            <Input
              style={{
                backgroundColor: colors.white,
              }}
              placeholder="กรุณากรอกข้อมูล"
              bordered={false}
              defaultValue={label}
              //suffix={<EditOutlined />}
              onChange={onChangeInput}
            />
          ) : (
            <Text color="white" fontWeight={700} fontSize={fontSize}>
              {label}
            </Text>
          )}
        </Col>
        {showButtonDelete && (
          <Col span={1}>
            <DeleteFilled
              style={{
                fontSize: "20px",
                color: colors.error,
                cursor: "pointer",
              }}
              onClick={onClickDelete}
            />
          </Col>
        )}
      </Row>
    </Header>
  );
}
