import {
  Badge,
  Button,
  Card,
  Col,
  Row,
  Divider,
  Input,
  Select,
  Form,
  Spin,
  Checkbox,
  Modal,
} from "antd";
import React, { useEffect, useState } from "react";
import color from "../../resource/color";
import Text from "../../components/Text/Text";
import HeaderInput from "../../components/Header/HeaderInput";
import Contrainer from "../../components/Contrainer/Contrainer";
import {
  ArrowRightOutlined,
  CloseCircleOutlined,
  CopyOutlined,
  DeleteFilled,
  EditFilled,
  EditOutlined,
  PlusCircleFilled,
  PlusSquareFilled,
} from "@ant-design/icons";
import { Footer } from "antd/lib/layout/layout";
import Header from "../../components/Header/Header";
import { useForm } from "antd/lib/form/Form";
import { SettingDatasource } from "../../datasource/settingDatasource";
import Swal from "sweetalert2";
import Buttons from "../../components/Button/Button";
import { YearList } from "../../definitions/Years";

const SelfReflectionConditon: React.FC = () => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const profile = JSON.parse(localStorage.getItem("rainbowProfile")!);
  const yearList = YearList();
  const [data, setData] = useState<any>([]);
  const [year, setYear] = useState<any>(new Date().getFullYear());
  const [dupYear, setDupYear] = useState<any>();
  const [deleteList, setDeleteList] = useState<any>([]);
  const [openDep, setOpenDep] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const getCondition = async () => {
    setIsEdit(false);
    setLoading(false);
    await SettingDatasource.getSelfCondition(year).then((res) => {
      setDupYear(year + 1);
      setData(res);
      form.setFieldValue("item", res);
      setLoading(true);
    });
  };

  useEffect(() => {
    getCondition();
  }, [year]);

  const addGroupCondition = () => {
    form.setFieldValue("item", [
      ...data,
      {
        sectionYear: year,
        sectionOrder: data.length + 1 || 1,
        sectionName: "",
        updateBy: profile?.firstname + " " + profile?.lastname,
        sectionSubjectDetail: [
          {
            key: 1,
            sectionSubjectTitle: "",
            sectionSubjectDetail: "",
            requireFill: true,
          },
        ],
      },
    ]);
    setData(form.getFieldValue("item"));
  };
  const addConditionInGroup = (k: any) => {
    const map = data.map((x: any) => {
      const find = x.sectionOrder === k;
      if (find) {
        return {
          ...x,
          ...x.sectionSubjectDetail.push({
            key: x.sectionSubjectDetail.length + 1,
            sectionSubjectTitle: "",
            sectionSubjectDetail: "",
            requireFill: true,
          }),
        };
      } else {
        return x;
      }
    });
    setData(map);
    form.setFieldValue("item", map);
  };
  const deleteCondition = async (g: any, k?: any) => {
    await setData([]);
    let payload = form.getFieldsValue(true).item;
    if (k > 0) {
      const map = payload.map((x: any) => {
        const find = x.sectionOrder === g;
        if (find) {
          const filter = x.sectionSubjectDetail
            .filter((y: any) => y.key !== k)
            .map((z: any, i: any) => {
              return { ...z, key: i + 1 };
            });
          return {
            ...x,
            sectionSubjectDetail: filter,
          };
        } else {
          return x;
        }
      });
      setData(map);
      form.setFieldValue("item", map);
    } else {
      const find = payload.find((x: any) => x.sectionOrder === g);
      if (find) {
        setDeleteList([...deleteList, find.selfReflectionSettingId]);
      }
      const fillter = payload.filter((x: any) => x.sectionOrder !== g);
      setData(fillter);
      form.setFieldValue("item", fillter);
    }
  };
  const onChangeCondition = (e: any, name: any, g: number, k?: number) => {
    let payload = form.getFieldsValue(true).item;
    if (k || 0 > 0) {
      payload = payload.map((x: any) => {
        const map = x.sectionOrder === g;
        if (map) {
          return {
            ...x,
            sectionSubjectDetail: x.sectionSubjectDetail.map((y: any) => {
              const mapDetail = y.key === k;
              if (mapDetail) {
                if (name === "sectionSubjectTitle") {
                  return { ...y, sectionSubjectTitle: e };
                } else {
                  return { ...y, sectionSubjectDetail: e };
                }
              } else {
                return { ...y };
              }
            }),
          };
        } else {
          return { ...x };
        }
      });
    } else {
      payload = payload.map((x: any) => {
        const map = x.sectionOrder === g;
        if (map) {
          return { ...x, sectionName: e };
        } else {
          return { ...x };
        }
      });
    }
    setData(payload);
    form.setFieldValue("item", payload);
  };
  const onCheckBoxCondition = (e: any, g: number, k?: number) => {
    let payload = form.getFieldsValue(true).item;
    const findGroup = payload.map((x: any) => {
      const map = x.sectionOrder === g;
      if (map) {
        return {
          ...x,
          sectionSubjectDetail: x.sectionSubjectDetail.map((y: any) => {
            const mapDetail = y.key === k;
            if (mapDetail) {
              return { ...y, requireFill: e };
            } else {
              return { ...y };
            }
          }),
        };
      } else {
        return { ...x };
      }
    });
    form.setFieldValue("item", findGroup);
    setData(findGroup);
  };
  const dupCondition = async () => {
    setOpenDep(false);
    const checkDup = await SettingDatasource.getSelfCondition(dupYear).then(
      (res) => {
        return res;
      }
    );
    if (checkDup.length > 0) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "ไม่สามารถคัดลอกได้",
        text: `ปี ${dupYear} มีเงื่อนไขแล้ว`,
        showConfirmButton: true,
        width: 320,
      });
    } else {
      const dupData = data.map((el: any) => {
        return {
          sectionYear: dupYear,
          sectionOrder: el.sectionOrder,
          sectionName: el.sectionName,
          updateBy: profile?.firstname + " " + profile?.lastname,
          sectionSubjectDetail: el.sectionSubjectDetail,
        };
      });
      submit(dupData);
      setYear(dupYear);
    }
  };
  const submit = async (obj?: any) => {
    setOpenDep(false);
    let payload: any = obj || data;
    payload = payload.map((x: any) => {
      const mapDetail = x.sectionSubjectDetail.filter(
        (y: any) => y.sectionSubjectTitle !== ""
      );
      delete x.sectionSubjectDetail;
      return {
        ...x,
        sectionSubjectDetails: mapDetail,
      };
    });
    payload = payload.filter((x: any) => x.sectionName !== "");

    const newdata = { data: payload };
    deleteList.forEach(async (item: any) => {
      await SettingDatasource.deleteSelfCondition(item);
    });
    await SettingDatasource.createSelfCondition(newdata).then((res) => {
      if (res.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: obj ? "คัดลอกสำเร็จ" : "บันทึกข้อมูลสำเร็จ",
          showConfirmButton: false,
          timer: 2000,
          width: 250,
        }).then(() => {
          getCondition();
        });
      }
    });
  };
  return (
    <>
      <Contrainer>
        <Header
          label="Self-Reflection Conditions"
          color={color.navy}
          fontSize={30}
          headerHeight={"60Px"}
        />
        <br />
        <Row justify={"start"} gutter={16}>
          <Col span={3} style={{ paddingTop: "3px" }}>
            <Text fontWeight={700}>เงื่อนไขประจำปี :</Text>
          </Col>
          <Col span={3} pull={1}>
            <Select
              onChange={(e) => setYear(e)}
              style={{ width: "100%", height: "100%" }}
              value={year}
              options={yearList.map((y: any) => {
                return {
                  label: y,
                  value: y,
                };
              })}
            />
          </Col>
          {data.length > 0 && (
            <>
              {!isEdit && (
                <Col span={2} pull={1}>
                  <Buttons
                    bgColor={color.navy}
                    onClick={() => setOpenDep(!openDep)}
                    icon={<CopyOutlined style={{ color: "white" }} />}
                    text="คัดลอก"
                  />
                </Col>
              )}
              {!isEdit ? (
                <Col push={!isEdit ? 14 : 16}>
                  <Buttons
                    bgColor={color.navy}
                    onClick={() => setIsEdit(!isEdit)}
                    icon={<EditFilled style={{ color: "white" }} />}
                    text="แก้ไข"
                  />
                </Col>
              ) : (
                <Col push={!isEdit ? 14 : 16}>
                  <Buttons
                    bgColor={color.error}
                    onClick={() => getCondition()}
                    icon={<CloseCircleOutlined style={{ color: "white" }} />}
                    text="ยกเลิก"
                  />
                </Col>
              )}
            </>
          )}
        </Row>
        <br />
        <Form form={form}>
          {data?.map((x: any, i: number) => (
            <>
              <HeaderInput
                label={x.sectionName}
                fontSize={24}
                color={color.green}
                headerHeight={"50px"}
                align="start"
                showButtonDelete={isEdit && !x.isUsed ? true : false}
                onChangeInput={(e) =>
                  onChangeCondition(
                    e.target.value,
                    "sectionName",
                    x.sectionOrder
                  )
                }
                onClickDelete={() => deleteCondition(x.sectionOrder)}
                isEdit={isEdit}
              />
              <br />
              {x?.sectionSubjectDetail?.map((y: any, i: number) => (
                <Row
                  justify={"space-between"}
                  gutter={16}
                  style={{ padding: "10px" }}
                >
                  <Col span={24}>
                    <Badge.Ribbon
                      text={
                        <Text fontWeight={700} color="white" fontSize={24}>
                          {i + 1}
                        </Text>
                      }
                      placement="start"
                      color={color.navy}
                    >
                      <Card
                        style={{
                          backgroundColor: "#E1E5F2",
                          boxShadow: "5px 5px 5px -2px rgba(13,1,1,0.32)",
                        }}
                      >
                        {isEdit ? (
                          <Row justify={"space-between"} gutter={8}>
                            <Col span={10}>
                              <Form.Item noStyle>
                                <Input
                                  style={{
                                    backgroundColor: color.white,
                                    height: "40px",
                                  }}
                                  placeholder="กรุณากรอกหัวข้อ"
                                  bordered={false}
                                  defaultValue={y.sectionSubjectTitle}
                                  suffix=":"
                                  onChange={(e) =>
                                    onChangeCondition(
                                      e.target.value,
                                      "sectionSubjectTitle",
                                      x.sectionOrder,
                                      y.key
                                    )
                                  }
                                  autoComplete="off"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item noStyle>
                                <Input
                                  style={{
                                    backgroundColor: color.white,
                                    height: "40px",
                                  }}
                                  placeholder="กรุณากรอกรายละเอียด"
                                  bordered={false}
                                  defaultValue={y.sectionSubjectDetail}
                                  suffix={<EditOutlined />}
                                  autoComplete="off"
                                  onChange={(e) =>
                                    onChangeCondition(
                                      e.target.value,
                                      "sectionSubjectDetail",
                                      x.sectionOrder,
                                      y.key
                                    )
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col span={1}>
                              <Form.Item noStyle name="require">
                                <Checkbox
                                  style={{ paddingTop: "10px" }}
                                  defaultChecked={y.requireFill}
                                  onChange={(e) =>
                                    onCheckBoxCondition(
                                      e.target.checked,
                                      x.sectionOrder,
                                      y.key
                                    )
                                  }
                                >
                                  บังคับ
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            {!x.isUsed && (
                              <Col
                                span={1}
                                style={{
                                  paddingTop: "10px",
                                  paddingLeft: "15px",
                                }}
                              >
                                <DeleteFilled
                                  style={{
                                    fontSize: "20px",
                                    color: color.error,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    deleteCondition(x.sectionOrder, y.key)
                                  }
                                />
                              </Col>
                            )}
                          </Row>
                        ) : (
                          <Row>
                            <Col span={22}>
                              <Text fontWeight={700}>
                                {y.sectionSubjectTitle}{" "}
                                {y.sectionSubjectDetail &&
                                  `: ${y.sectionSubjectDetail}`}
                              </Text>
                            </Col>
                            <Col span={2}>
                              <Checkbox
                                style={{
                                  color: color.navy,
                                  cursor: "not-allowed",
                                }}
                                checked={y.requireFill}
                              >
                                <Text fontWeight={700}>บังคับ</Text>
                              </Checkbox>
                            </Col>
                          </Row>
                        )}
                      </Card>
                    </Badge.Ribbon>
                  </Col>
                </Row>
              ))}
              {isEdit && (
                <Col span={24} style={{ padding: "10px" }}>
                  <Button
                    type="dashed"
                    style={{
                      width: "100%",
                      height: "100%",
                      //backgroundColor: "#93beed",
                      borderColor: "#689cd4",
                    }}
                    onClick={() => addConditionInGroup(x.sectionOrder)}
                  >
                    <PlusCircleFilled
                      style={{ fontSize: "20px", color: "#689cd4" }}
                    />
                    <Text
                      fontSize={20}
                      fontWeight={700}
                      style={{ color: "#689cd4" }}
                    >
                      เพิ่มเงื่อนไข
                    </Text>
                  </Button>
                </Col>
              )}
              <Divider />
            </>
          ))}
        </Form>
        {!loading ? (
          <Row justify={"center"}>
            <Spin style={{ paddingTop: "10%" }} />
          </Row>
        ) : (
          <Col
            span={24}
            style={{ height: data?.length > 0 ? "50px" : "200px" }}
          >
            <Button
              type="dashed"
              style={{
                width: "100%",
                height: "100%",
                borderColor: "#689cd4",
                backgroundColor: "#689cd4",
              }}
              onClick={() => {
                addGroupCondition();
                setIsEdit(true);
              }}
            >
              <PlusSquareFilled
                style={{ fontSize: "20px", color: color.white }}
              />
              <Text
                fontSize={data?.length > 0 ? 24 : 30}
                fontWeight={700}
                color="white"
              >
                เพิ่มหัวข้อ
              </Text>
            </Button>
          </Col>
        )}
        <br />
      </Contrainer>
      <Footer style={{ backgroundColor: color.white }}>
        {isEdit && (
          <Row justify={"space-between"}>
            <Buttons
              style={{ height: "40px" }}
              bgColor={color.error}
              onClick={() => {
                getCondition();
              }}
              text="ยกเลิก"
            />
            <Buttons
              style={{ height: "40px" }}
              bgColor={color.navy}
              onClick={() => submit()}
              text="บันทึก"
            />
          </Row>
        )}
      </Footer>
      {openDep && (
        <Modal
          title={<Text fontWeight={700}>คัดลอก</Text>}
          open={openDep}
          centered
          footer
          onCancel={() => setOpenDep(!openDep)}
        >
          <Row justify={"space-around"}>
            <Col span={10}>
              <Text fontSize={14} fontWeight={500}>
                Condiion ประจำปี :{" "}
              </Text>
              <Input value={year} disabled />
            </Col>
            <ArrowRightOutlined style={{ paddingTop: "25px" }} />
            <Col span={11}>
              <Text fontSize={14} fontWeight={500}>
                คัดลอกไปยังปี :{" "}
              </Text>
              <Select
                style={{ width: "80%", height: "60%" }}
                value={dupYear}
                options={yearList
                  .filter((x: any) => x > year)
                  .map((y: any) => {
                    return {
                      label: y,
                      value: y,
                    };
                  })}
                onChange={(e) => setDupYear(e)}
              />
            </Col>
          </Row>
          <br />
          <Row justify={"end"}>
            <Buttons
              bgColor={color.navy}
              onClick={() => dupCondition()}
              text="บันทึก"
            />
          </Row>
        </Modal>
      )}
    </>
  );
};

export default SelfReflectionConditon;
