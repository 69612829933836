import { Col, Row } from "antd";
import { Header } from "antd/lib/layout/layout";
import Text from "../../components/Text/Text";

export interface HeaderProp {
  label: string;
  color?: string;
  fontSize?:
    | 12
    | 14
    | 16
    | 18
    | 20
    | 22
    | 24
    | 26
    | 28
    | 30
    | 32
    | 34
    | 36
    | 38
    | 40
    | 46
    | 50;
  headerHeight?: "50px" | "60Px";
  align?: "start" | "center" | "end";
  showButton?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
}
export default function Headers({
  label,
  color,
  fontSize,
  align,
  showButton,
  headerHeight,
  style,
}: HeaderProp) {
  return (
    <Header
      style={{
        backgroundColor: color,
        borderRadius: "8px",
        height: headerHeight,
        ...style,
      }}
    >
      <Row justify={align || "center"} gutter={8}>
        <Col span={showButton ? 22 : ""}>
          <Text color="white" fontWeight={700} fontSize={fontSize}>
            {label}
          </Text>
        </Col>
      </Row>
    </Header>
  );
}
