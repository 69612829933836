export default {
  black: "#2B2B2B",
  white: "#FFFFFF",
  success: "#2ED477",
  warning: "#F2994A",
  error: "#EB5757",
  green: "#5E8D79",
  navy: "#313D87",
  yellow: "#FFFACC",
  pink: "#FFDFDE",
  orange: "#F5A631",
  darkyellow: "#E1CF27",
  gray: "#c9c9c9",
  lightgray: "#E4E7E5",
};
