import {
  Badge,
  Card,
  Col,
  Input,
  Row,
  Button,
  Spin,
  Form,
  Divider,
} from "antd";
import React, { useEffect, useState } from "react";
import Contrainer from "../../components/Contrainer/Contrainer";
import Header from "../../components/Header/Header";
import HeaderInput from "../../components/Header/HeaderInput";
import color from "../../resource/color";
import Text from "../../components/Text/Text";
import {
  ScoreList,
  SCORE_MAPPING,
  SCORE_MAPPING_COLOR,
  SCORE_MAPPING_COLORS,
  SCORE_MAPPING_DES,
} from "../../definitions/score";
import {
  CloseCircleOutlined,
  EditFilled,
  EditOutlined,
  PlusSquareFilled,
} from "@ant-design/icons";
import { Footer } from "antd/lib/layout/layout";
import { useForm } from "antd/es/form/Form";
import { SettingDatasource } from "../../datasource/settingDatasource";
import Swal from "sweetalert2";
import Buttons from "../../components/Button/Button";

const ScoreConditon: React.FC = () => {
  const profile = JSON.parse(localStorage.getItem("rainbowProfile")!);
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [deleteList, setDeleteList] = useState<any>([]);

  const getScoreCondition = async () => {
    setIsEdit(false);
    await SettingDatasource.getScoreCondition().then((res) => {
      setLoading(false);
      const mapScore = res.map((x: any) => {
        const scoreList = ScoreList.map((s) => {
          const find = x.scoreConditionDetail.find(
            (a: any) => `${a.key}` === `${s}`
          );
          return {
            key: Number(s),
            value: s,
            description: find ? find.description : "",
          };
        });
        return {
          ...x,
          scoreConditionDetail: scoreList,
        };
      });
      setData(mapScore);
      form.setFieldValue("data", mapScore);
    });
    setLoading(true);
  };

  useEffect(() => {
    getScoreCondition();
  }, []);

  const addGroupCondition = () => {
    const scoreList = ScoreList.map((s) => {
      return {
        key: Number(s),
        value: s,
        description: "",
      };
    });
    form.setFieldValue("data", [
      ...data,
      {
        scoreConditionId: "",
        scoreConditionName: "",
        updateBy: profile?.firstname + " " + profile?.lastname,
        scoreConditionOrder: data.length + 1,
        scoreConditionDetail: scoreList,
      },
    ]);
    setData(form.getFieldValue("data"));
  };
  const onChangeCondition = (e: any, g: number, s?: number) => {
    let payload = form.getFieldsValue(true).data;
    if (s || 0 > 0) {
      payload = payload.map((x: any) => {
        const map = x.scoreConditionOrder === g;
        if (map) {
          return {
            ...x,
            scoreConditionDetail: x.scoreConditionDetail.map((y: any) => {
              const mapDetail = `${y.key}` === `${s}`;
              if (mapDetail) {
                return { ...y, description: e };
              } else {
                return { ...y };
              }
            }),
          };
        } else {
          return { ...x };
        }
      });
    } else {
      payload = payload.map((x: any) => {
        const map = x.scoreConditionOrder === g;
        if (map) {
          return { ...x, scoreConditionName: e };
        } else {
          return { ...x };
        }
      });
    }
    setData(payload);
    form.setFieldValue("data", payload);
  };
  const deleteCondition = async (g: number) => {
    await setData([]);
    let payload = form.getFieldsValue(true).data;
    const find = payload.find(
      (x: any) => x.scoreConditionOrder === g
    ).scoreConditionId;
    const filter = payload
      .filter((x: any) => x.scoreConditionOrder !== g)
      .map((y: any, i: number) => {
        return { ...y, scoreConditionOrder: i + 1 };
      });

    form.setFieldValue("data", filter);
    setData(filter);
    setDeleteList([...deleteList, find].filter((x) => x !== ""));
  };
  const submit = async () => {
    let payload = form.getFieldsValue(true).data;
    payload = payload.map((x: any) => {
      const mapDetail = x.scoreConditionDetail;
      !x.scoreConditionId && delete x.scoreConditionId;
      delete x.scoreConditionDetail;
      delete x.createDate;
      delete x.updateDate;
      return {
        ...x,
        scoreConditionDetails: mapDetail,
      };
    });
    payload = payload.filter((x: any) => x.scoreConditionName !== "");
    const newData = { data: payload };
    deleteList.forEach(async (item: any) => {
      await SettingDatasource.deleteScoreCondition(item);
    });
    await SettingDatasource.createScoreCondition(newData).then((res) => {
      if (res.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "บันทึกข้อมูลสำเร็จ",
          showConfirmButton: false,
          timer: 2000,
          width: 250,
        }).then(() => {
          getScoreCondition();
        });
      }
    });
  };

  return (
    <>
      <Contrainer>
        <Header
          label="Score Conditions"
          color={color.navy}
          fontSize={30}
          headerHeight={"60Px"}
        />
        <br />
        <Row justify={"end"} gutter={16}>
          {data?.length > 0 && !isEdit && (
            <Col>
              <Buttons
                onClick={() => setIsEdit(!isEdit)}
                text="แก้ไข"
                icon={<EditFilled style={{ color: "white" }} />}
              />
            </Col>
          )}
          {data.length > 0 && isEdit && (
            <Col>
              <Buttons
                bgColor={color.error}
                onClick={() => {
                  setIsEdit(!isEdit);
                  getScoreCondition();
                }}
                icon={<CloseCircleOutlined style={{ color: "white" }} />}
                text="ยกเลิก"
              />
            </Col>
          )}
        </Row>
        <br />
        {data?.map((x: any) => (
          <Form form={form}>
            <HeaderInput
              label={x.scoreConditionName}
              color={color.green}
              fontSize={24}
              headerHeight={"50px"}
              align="start"
              showButtonDelete={isEdit}
              onChangeInput={(e) =>
                onChangeCondition(e.target.value, x.scoreConditionOrder)
              }
              onClickDelete={() => deleteCondition(x.scoreConditionOrder)}
              isEdit={isEdit}
            />
            <br />
            {x?.scoreConditionDetail?.map((y: any, i: number) => (
              <Row
                justify={"space-between"}
                gutter={16}
                style={{ padding: "10px" }}
              >
                <Col span={24}>
                  <Badge.Ribbon
                    text={
                      <Text fontWeight={700} color="white" fontSize={24}>
                        {SCORE_MAPPING[y.value]}
                      </Text>
                    }
                    placement="start"
                    color={SCORE_MAPPING_COLORS[y.value]}
                  >
                    <Card
                      style={{
                        backgroundColor: "#E1E5F2",
                        boxShadow: "5px 5px 5px -2px rgba(13,1,1,0.32)",
                      }}
                    >
                      <Row>
                        <Col span={8} style={{ paddingLeft: "30px" }}>
                          <Text
                            color={SCORE_MAPPING_COLOR[y.value]}
                            fontWeight={700}
                          >
                            {SCORE_MAPPING_DES[y.value]}
                          </Text>
                        </Col>
                        {isEdit ? (
                          <>
                            <Col span={16}>
                              <Form.Item noStyle>
                                <Input
                                  style={{
                                    backgroundColor: color.white,
                                    height: "40px",
                                  }}
                                  placeholder="กรุณากรอกหัวข้อ"
                                  bordered={false}
                                  defaultValue={y.description}
                                  suffix={<EditOutlined />}
                                  onChange={(e) =>
                                    onChangeCondition(
                                      e.target.value,
                                      x.scoreConditionOrder,
                                      y.key
                                    )
                                  }
                                  autoComplete="off"
                                />
                              </Form.Item>
                            </Col>
                          </>
                        ) : (
                          <Col span={16}>
                            <Text fontWeight={700}>{y.description}</Text>
                          </Col>
                        )}
                      </Row>
                    </Card>
                  </Badge.Ribbon>
                </Col>
              </Row>
            ))}
            <Divider />
          </Form>
        ))}
        {!loading ? (
          <Row justify={"center"}>
            <Spin style={{ paddingTop: "10%" }} />
          </Row>
        ) : (
          <Col
            span={24}
            style={{ height: data?.length > 0 ? "50px" : "200px" }}
          >
            <Button
              type="dashed"
              style={{
                width: "100%",
                height: "100%",
                borderColor: "#689cd4",
                backgroundColor: "#689cd4",
              }}
              onClick={() => {
                addGroupCondition();
                setIsEdit(true);
              }}
            >
              <PlusSquareFilled
                style={{ fontSize: "20px", color: color.white }}
              />
              <Text
                fontSize={data?.length > 0 ? 24 : 30}
                fontWeight={700}
                color="white"
              >
                เพิ่มหัวข้อ
              </Text>
            </Button>
          </Col>
        )}
      </Contrainer>
      <Footer style={{ backgroundColor: color.white }}>
        {isEdit && (
          <Row justify={"space-between"}>
            <Button
              style={{ backgroundColor: color.error, height: "40px" }}
              onClick={() => {
                setIsEdit(!isEdit);
                getScoreCondition();
              }}
            >
              <Text color="white" fontWeight={700}>
                ยกเลิก
              </Text>
            </Button>
            <Button
              style={{ backgroundColor: color.navy, height: "40px" }}
              onClick={submit}
            >
              <Text color="white" fontWeight={700}>
                บันทึก
              </Text>
            </Button>
          </Row>
        )}
      </Footer>
    </>
  );
};
export default ScoreConditon;
