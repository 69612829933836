import moment, { Moment } from "moment";

const SLASH_DMY = "DD/MM";
const SLASH_DMYHM = "HH:mm น.";

export const dateFormatter = (d: string, withTime?: boolean) => {
  let date =
    moment(d).format(SLASH_DMY) + "/" + moment(d).add(543, "year").year();
  withTime && (date = date + " " + moment(d).format(SLASH_DMYHM));
  return date;
};
