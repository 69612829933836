export const CompanyList: any = [
  { key: 1, company: "ICPL" },
  { key: 2, company: "ICPF" },
  { key: 3, company: "ICK" },
  { key: 4, company: "ICPI" },
];
export const COMPAY_MAPPING_DES: any = {
  "1": "ICPL",
  "2": "ICPF",
  "3": "ICK",
  "4": "ICPI",
};
