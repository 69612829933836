import { AxiosResponse } from "axios";
import { BASE_URL, httpClient, intanceAuth } from "../config/develop-config";

const baseUrl = `${BASE_URL}`;

export class EmployeeApproveDatasource {
  static getEmployeeApprove(id: number) {
    return httpClient
      .get(`${baseUrl}/employee-approve/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static getEmployeeApproval(params: object) {
    return httpClient
      .get(`${baseUrl}/employee-approve`, { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static createEmployeeApprove(params: object) {
    return httpClient
      .post(`${baseUrl}/employee-approve`, params)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static getAllEmployee(params: object) {
    return httpClient
      .get(`${baseUrl}/employee`, { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static getEmployeeWithLevel(id: number) {
    return httpClient
      .get(`${baseUrl}/employee-approve/employee-all/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static deleteEmployee = async (id: string) => {
    return await httpClient
      .delete(`${baseUrl}/employee-approve/${id}`)
      .then((res: AxiosResponse) => res.data)
      .catch((err) => console.log(err));
  };
}
