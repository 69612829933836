import { AxiosResponse } from "axios";
import { BASE_URL, httpClient, intanceAuth } from "../config/develop-config";

const baseUrl = `${BASE_URL}`;

export class SettingDatasource {
  static getSelfCondition(year: any) {
    return httpClient
      .get(`${baseUrl}/self-reflection-setting/${year}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static createSelfCondition = async (data: object) => {
    return await httpClient
      .post(`${baseUrl}/self-reflection-setting`, data)
      .then((res: AxiosResponse) => res.data)
      .catch((err) => console.log(err));
  };
  static deleteSelfCondition = async (id: string) => {
    return await httpClient
      .delete(`${baseUrl}/self-reflection-setting/${id}`)
      .then((res: AxiosResponse) => res.data)
      .catch((err) => console.log(err));
  };
  static getScoreCondition() {
    return httpClient
      .get(`${baseUrl}/score-condition`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static createScoreCondition = async (data: object) => {
    return await httpClient
      .post(`${baseUrl}/score-condition`, data)
      .then((res: AxiosResponse) => res.data)
      .catch((err) => console.log(err));
  };
  static deleteScoreCondition = async (id: string) => {
    return await httpClient
      .delete(`${baseUrl}/score-condition/${id}`)
      .then((res: AxiosResponse) => res.data)
      .catch((err) => console.log(err));
  };
}
