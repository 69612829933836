import {
  Badge,
  Card,
  Col,
  ConfigProvider,
  Divider,
  Input,
  Progress,
  Row,
  Select,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import Contrainer from "../../components/Contrainer/Contrainer";
import Header from "../../components/Header/Header";
import color from "../../resource/color";
import Text from "../../components/Text/Text";
import { YearList } from "../../definitions/Years";
import { PeriodList } from "../../definitions/Period";
import { FileExcelFilled, SearchOutlined } from "@ant-design/icons";
import { reportDatasource } from "../../datasource/reportDatasource";
import { companyDatasource } from "../../datasource/companyDatasource";
import { AlignType } from "rc-table/lib/interface";
import { dateFormatter } from "../../utility/Formatter";
import Buttons from "../../components/Button/Button";
import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL } from "../../config/develop-config";

const PmsReport: React.FC = () => {
  let permission = JSON.parse(localStorage.getItem("rainbowPermission") || "");
  permission = permission?.permission?.filter((x: any) =>
    x.reportName.includes("PMS")
  );
  const [companyList, setCompanyList] = useState<any>(permission[0].company[0]);
  const [company, setCompany] = useState<string>(permission[0].company[0]);
  const yearList = YearList();
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [month, setMonth] = useState<number>(
    new Date().getMonth() + 1 >= 6 ? 13 : 14
  );
  const [departmentList, setDepartmentList] = useState<any>();
  const [dataDepart, setDataDepart] = useState<any>();
  const [dataSummary, setDataSummary] = useState<any>({
    totalEmp: 0,
    totalSelf: 0,
    totalPms: 0,
    percent: 0,
  });
  const [departmentId, setDepartmentId] = useState<number>();
  const [dataEmp, setDataEmp] = useState<any>();
  const [searchEmp, setSearchEmp] = useState<any>();
  const [searchName, setSearchName] = useState<string>();
  const [searchStatus, setSearchStatus] = useState<string>();

  const statusList = ["ยังไม่ได้ทำ", "กำลังทำ", "ทำเรียบร้อยแล้ว"];

  const getCompany = async (id: string) => {
    await companyDatasource.getAllCompany().then((res) => {
      const mapCompany = res.responseData.find(
        (x: any) => `${x.companyNameEn}` === `${id}`
      );
      setDepartmentList(mapCompany.department);
      setCompany(mapCompany.companyId);
    });
  };

  const getPmsSummary = async () => {
    await reportDatasource
      .getPmsSummaryReport({
        companyId: company,
        pmsYear: year,
        pmsMonth: month,
      })
      .then((res) => {
        const totalEmp = res?.responseData?.reduce(
          (x: any, y: any) => (x = Number(x) + Number(y.total_employee)),
          0
        );
        const totalSelf = res?.responseData?.reduce(
          (x: any, y: any) => (x = Number(x) + Number(y.total_self)),
          0
        );
        const totalPms = res?.responseData?.reduce(
          (x: any, y: any) => (x = Number(x) + Number(y.total_pms)),
          0
        );
        setDataSummary({
          totalEmp: totalEmp,
          totalSelf: totalSelf,
          totalPms: totalPms,
          percent: (totalPms / totalEmp) * 100,
        });
        setDataDepart(res.responseData);
      });
    await reportDatasource
      .getPmsDetailReport({
        companyId: company,
        pmsYear: year,
        pmsMonth: month,
        departmentId: departmentId,
      })
      .then((res) => {
        setSearchEmp(res.responseData);
        setDataEmp(res.responseData);
      });
  };

  useEffect(() => {
    companyList && getCompany(companyList);
    getPmsSummary();
  }, [company, year, month, departmentId]);

  const columnsDepart = [
    {
      title: "Department",
      dataIndex: "department_name",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text level={6} fontWeight={500}>
              {value}
            </Text>
          ),
        };
      },
    },
    {
      title: "Total Employee",
      dataIndex: "total_employee",
      align: "center" as AlignType,
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text level={6} fontWeight={500}>
              {value || 0}
            </Text>
          ),
        };
      },
    },
    {
      title: "Self-Reflection Finished",
      dataIndex: "total_self",
      align: "center" as AlignType,
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text
              level={6}
              fontWeight={500}
              color={value === row.total_employee ? "success" : "black"}
            >
              {value || 0}/{row.total_employee}
            </Text>
          ),
        };
      },
    },
    {
      title: "PMS Finished",
      dataIndex: "total_pms",
      align: "center" as AlignType,
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text
              level={6}
              fontWeight={500}
              color={value === row.total_employee ? "success" : "black"}
            >
              {value || 0}/{row.total_employee}
            </Text>
          ),
        };
      },
    },
    {
      title: "Average %",
      dataIndex: "avg_percent",
      align: "center" as AlignType,
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text
              level={6}
              fontWeight={500}
              color={Number(value) === 100 ? "success" : "black"}
            >
              {Number(value).toFixed(0) || 0} %
            </Text>
          ),
        };
      },
    },
  ];
  const columnsEmployee = [
    {
      title: "Employee Name",
      dataIndex: "empName",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text level={6} fontWeight={500}>
              {row.firstname} {row.lastname}
            </Text>
          ),
        };
      },
    },
    {
      title: "Company",
      dataIndex: "company_name_en",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text level={6} fontWeight={500}>
              {value}
            </Text>
          ),
        };
      },
    },
    {
      title: "Department",
      dataIndex: "department_name",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text level={6} fontWeight={500}>
              {value}
            </Text>
          ),
        };
      },
    },
    {
      title: "Self-Reflection Status",
      dataIndex: "self_status",
      align: "center" as AlignType,
      render: (value: any, row: any, index: number) => {
        const mapStatus: any = {
          ทำเรียบร้อยแล้ว: "success",
          กำลังทำ: "darkyellow",
          ยังไม่ได้ทำ: "error",
        };
        return {
          children: (
            <Text level={6} fontWeight={500} color={mapStatus[value]}>
              {value}
            </Text>
          ),
        };
      },
    },
    {
      title: "PMS Status",
      dataIndex: "pms_status",
      align: "center" as AlignType,
      render: (value: any, row: any, index: number) => {
        const mapStatus: any = {
          ทำเรียบร้อยแล้ว: "success",
          กำลังทำ: "darkyellow",
          ยังไม่ได้ทำ: "error",
        };
        return {
          children: (
            <Text level={6} fontWeight={500} color={mapStatus[value]}>
              {value}
            </Text>
          ),
        };
      },
    },
    {
      title: "Update Date",
      dataIndex: "pms_update_date",
      align: "center" as AlignType,
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              {value ? (
                <Text level={6} fontWeight={500}>
                  {dateFormatter(value, true) || "-"}
                </Text>
              ) : (
                <Text level={6} fontWeight={500}>
                  -
                </Text>
              )}
            </>
          ),
        };
      },
    },
  ];

  const onChangeSearch = (e: any, type: string) => {
    let mapData: any = [];
    if (type === "emp") {
      setSearchName(e);
      mapData = dataEmp.filter((x: any) => {
        const emp =
          !e ||
          x.firstname.toLowerCase().includes(e.toLowerCase()) ||
          x.lastname.toLowerCase().includes(e.toLowerCase());
        const status = !searchStatus || x.pms_status.includes(searchStatus);
        return emp && status;
      });
    } else {
      setSearchStatus(e);
      mapData = dataEmp.filter((x: any) => {
        const emp =
          !searchName ||
          x.firstname.toLowerCase().includes(searchName.toLowerCase()) ||
          x.lastname.toLowerCase().includes(searchName.toLowerCase());
        const status = !e || x.pms_status.includes(e);
        return emp && status;
      });
    }
    setSearchEmp(mapData);
  };

  const onDownloadExcel = async () => {
    const headers = { "Content-Type": "blob" };
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${BASE_URL}/report/pms-report-excel/${company}/${year}/${month}`,
      responseType: "arraybuffer",
      headers,
    };
    const response = await axios(config);

    const file = new Blob([response.data], {
      type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const mapType = month === 13 ? "MidYear" : "FinalYear";
    const url = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `PMS-${mapType}-${year}.xlsx`);

    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  return (
    <>
      <Contrainer>
        <Header
          label="PMS Summary Report"
          color={color.navy}
          fontSize={30}
          headerHeight={"60Px"}
        />
        <br />
        <Row justify={"start"} gutter={16}>
          {permission.length > 0 && (
            <>
              <Col span={2} style={{ paddingTop: "3px" }}>
                <Text fontWeight={700}>Company :</Text>
              </Col>
              <Col span={3}>
                <Select
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setSearchName(undefined);
                    setSearchStatus(undefined);
                    getCompany(e);
                    setCompanyList(e);
                  }}
                  defaultValue={companyList}
                  options={permission[0].company.map((y: any) => {
                    return {
                      label: <Text fontWeight={700}>{y}</Text>,
                      value: y,
                    };
                  })}
                />
              </Col>
            </>
          )}
          <Col span={permission.length > 0 ? 9 : 14}></Col>
          <Col span={2} style={{ paddingTop: "3px" }}>
            <Text fontWeight={700}>Year :</Text>
          </Col>
          <Col span={3}>
            <Select
              style={{ width: "100%" }}
              defaultValue={year}
              onChange={(e) => setYear(e)}
              options={yearList.map((y: any) => {
                return {
                  label: <Text fontWeight={700}>{y}</Text>,
                  value: y,
                };
              })}
            />
          </Col>
          <Col span={2} style={{ paddingTop: "3px" }}>
            <Text fontWeight={700}>Type :</Text>
          </Col>
          <Col span={3}>
            <Select
              style={{ width: "100%" }}
              defaultValue={month}
              onChange={(e) => setMonth(e)}
              options={PeriodList.map((y) => {
                return {
                  label: <Text fontWeight={700}>{y.month}</Text>,
                  value: y.key,
                };
              })}
            />
          </Col>
        </Row>
        <br />
        <Row justify={"space-between"} gutter={24}>
          <Col span={12}>
            <Badge.Ribbon
              text={
                <Text fontWeight={700} color="white" fontSize={24}>
                  คิดเป็นจำนวนตัวเลข
                </Text>
              }
              placement="start"
              color={color.navy}
            >
              <Card style={{ height: "200px", backgroundColor: "#E1E5F2" }}>
                <Row justify={"center"} style={{ paddingTop: "40px" }}>
                  <Col>
                    <Text fontWeight={700} fontSize={60}>
                      {dataSummary.totalPms}
                    </Text>
                    <Text fontWeight={700} fontSize={40}>
                      /{dataSummary.totalEmp} คน
                    </Text>
                  </Col>
                </Row>
                <Row justify={"end"} style={{ paddingTop: "10px" }}>
                  <Text fontWeight={600} fontSize={14}>
                    *พนักงานที่ทำแล้ว/พนักงานทั้งหมด
                  </Text>
                </Row>
              </Card>
            </Badge.Ribbon>
          </Col>
          <Col span={12}>
            <Badge.Ribbon
              text={
                <Text fontWeight={700} color="white" fontSize={24}>
                  คิดเป็น %
                </Text>
              }
              placement="start"
              color={color.navy}
            >
              <Card
                style={{
                  height: "200px",
                  backgroundColor: "#E1E5F2",
                }}
              >
                <Row justify={"center"}>
                  <Progress
                    type="circle"
                    percent={dataSummary.percent.toFixed(0)}
                    size={150}
                    strokeLinecap="round"
                    strokeColor={color.navy}
                    format={(percent) => (
                      <Text fontSize={40} fontWeight={700}>
                        {percent} %
                      </Text>
                    )}
                  />
                </Row>
              </Card>
            </Badge.Ribbon>
          </Col>
        </Row>
        <br />
        <Header
          align="start"
          label="สถานะการส่ง Self-Reflection แยกตามแผนก"
          color={color.navy}
          fontSize={22}
          headerHeight="50px"
        />
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: "#F5F5F5",
                headerColor: color.black,
              },
            },
          }}
        >
          <Table
            dataSource={dataDepart || []}
            columns={columnsDepart}
            pagination={false}
            scroll={{ y: 300 }}
          />
        </ConfigProvider>
        <br />
        <Header
          align="start"
          label="สถานะการทำ PMS พนักงานของคุณ"
          color={color.navy}
          fontSize={22}
          headerHeight="50px"
        />
        <br />
        <Row justify={"start"} gutter={16}>
          <Col span={3}></Col>
          <Col span={2} style={{ paddingTop: "3px" }}>
            <Text fontWeight={700}>แผนก :</Text>
          </Col>
          <Col span={5}>
            <Select
              allowClear
              placeholder="ค้นหาแผนก"
              style={{ width: "100%" }}
              onChange={(e) => setDepartmentId(e)}
              options={departmentList?.map((y: any) => {
                return {
                  label: <Text fontWeight={700}>{y.departmentName}</Text>,
                  value: y.departmentId,
                };
              })}
            />
          </Col>
          <Col span={2} style={{ paddingTop: "3px" }}>
            <Text fontWeight={700}>สถานะ :</Text>
          </Col>
          <Col span={3}>
            <Select
              value={searchStatus}
              allowClear
              placeholder="ค้นหาสถานะ"
              style={{ width: "100%" }}
              onChange={(e) => {
                onChangeSearch(e, "status");
              }}
              options={statusList?.map((y: any) => {
                return {
                  label: <Text fontWeight={700}>{y}</Text>,
                  value: y,
                };
              })}
            />
          </Col>
          <Col span={2} style={{ paddingTop: "3px" }}>
            <Text fontWeight={700}>ชื่อพนักงาน :</Text>
          </Col>
          <Col span={4}>
            <Input
              value={searchName}
              style={{ fontFamily: "Sarabun", width: "100%" }}
              suffix={<SearchOutlined />}
              placeholder="ค้นหาชื่อพนักงาน"
              onChange={(e) => {
                onChangeSearch(e.target.value, "emp");
              }}
            />
          </Col>
          <Col span={2}>
            <Buttons
              bgColor={color.navy}
              onClick={() => onDownloadExcel()}
              icon={
                <FileExcelFilled style={{ color: "white", fontSize: "20px" }} />
              }
              text="ดาวน์โหลด"
            />
          </Col>
        </Row>
        <br />
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: "#F5F5F5",
                headerColor: color.black,
              },
            },
          }}
        >
          <Table
            dataSource={searchEmp || []}
            columns={columnsEmployee}
            pagination={false}
            scroll={{ y: 500 }}
          />
        </ConfigProvider>
        <Divider />
      </Contrainer>
    </>
  );
};

export default PmsReport;
