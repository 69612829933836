import { AxiosResponse } from "axios";
import { BASE_URL, httpClient, intanceAuth } from "../config/develop-config";

const baseUrl = `${BASE_URL}`;

export class SelfReflcetionDatasource {
  static getSelfReflection(params: object) {
    return httpClient
      .get(`${baseUrl}/self-reflection`, { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static createSelfReflcetion = async (data: any) => {
    const newData = {
      data: data?.map((x: any) => {
        return {
          ...x,
          selfRefelcValue: x.selfRefelcValue.map((y: any) => {
            return { key: y.key, value: y.value };
          }),
        };
      }),
    };
    return await httpClient
      .post(`${baseUrl}/self-reflection`, newData)
      .then((res: AxiosResponse) => res.data)
      .catch((err) => console.log(err));
  };
  static submitSelfRefect = async (selfId: string) => {
    return await httpClient
      .post(`${baseUrl}/self-reflection/update-status-submit/${selfId}`)
      .then((res: AxiosResponse) => res.data)
      .catch((err) => console.log(err));
  };
}
