import { AxiosResponse } from "axios";
import { BASE_URL, httpClient, intanceAuth } from "../config/develop-config";

const baseUrl = `${BASE_URL}/kci`;

export class categotyKciDatasource {
  static getCategory() {
    return httpClient
      .get(`${baseUrl}/category`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
